import { Card as ParticleCard } from 'antd';

import styled from 'styled-components';

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Haffer XH', 'Helvetica Neue', Arial, Verdana, sans-serif;
  padding: 40px 0;
`;

export const QuantcastLogo = styled.div`
  mask-position: center;
  mask-size: contain;
  mask-image: url("https://static.quantcast.com/images/quantcast-logo-2024.svg");
  mask-repeat: no-repeat;
  background-color: var(--matter-dark-blue);
  height: 36px;
  width: 235px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const ProductContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const Card = styled(ParticleCard)`
  background-color: #fff;
  width: 400px;
  padding: 0;
  
  .particle-card-body {
    padding: 0px;
  }

  @media only screen and (max-height: 750px) {
    margin-top: 10px;
  }

  .simple-card__content {
    padding: 30px 20px 30px 20px;
  }

  .particle-form-item-label {
    padding-bottom: 4px;
  }

  .particle-form-item-label > label {
    color: var(--gray-8);
    font-weight: 600;
  }
  
  .particle-alert {
    margin-bottom: 10px;
  }
`;

export const SubHeader = styled.div`
    font-size: 16px;
    padding-bottom: 16px;
`;

export const LinkSection = styled.div`
    line-height: 32px;
    padding-top: 16px;
    text-align: center;
`;