export const INITIAL_STATE = {
  fetchingProfile: true,
  profileFetched: false,
  savingProfile: false,
  profileSaved: false,
  changingPassword: false,
  passwordSaved: false,
  error: null,
  idpProvided: false,
  profile: {
    email: '',
    firstName: '',
    lastName: '',
    timezone: ''
  }
};
