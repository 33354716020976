import { Form as ParticleForm, Result as ParticleResult } from 'antd'
import ParticleAlert from '@qc/particle/lib/components/alert'

import styled from 'styled-components';

export const QuantcastLogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`;

export const QuantcastLogo = styled.div`
  mask-position: center;
  mask-size: contain;
  mask-image: url("https://static.quantcast.com/images/quantcast-logo-2024.svg");
  mask-repeat: no-repeat;
  background-color: var(--matter-dark-blue);
  height: 36px;
  width: 235px;
  margin-bottom: 40px;
  cursor: pointer;
`;

export const SignUpViewQuantcastLogo = styled(QuantcastLogo)`
  background-color: var(--matter-bright-blue);
  margin-bottom: 50px;
`;

export const PublisherIndication = styled.div`
  font-size: 20px;
  font-weight: 600;
  color: var(--matter-white);
  width: 100%;
  text-align: left;
  padding-bottom: 10px;
`

export const Alert = styled(ParticleAlert)`
  margin-bottom: 24px;
`;

export const Form = styled(ParticleForm)`
  width: 100%;
  max-width: 500px;
`

export const FormControl = styled(ParticleForm.Item)`
  .progress-spinner__root {
    width: 20px;
    height: 20px;
  }
  .matter-btn,
  .particle-btn {
    width: 100%;
  }
  ul {
    padding: 5px;
  }
  .matter-form-item-label,
  .matter-form-item-control {
    flex: 0 0 auto !important;
  }

  .matter-form-item-label > label {
    color: ${({ textType }) => textType === 'primary' ? 'var(--matter-gray-2)' : 'var(--matter-gray-6)'};
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'Haffer XH', 'Helvetica Neue', Arial, Verdana, sans-serif;
  height: auto;
  justify-content: center;
  width: 100%;
  max-width: 500px;

  #ps-group-advertise-signup-terms {
    div {
      color: var(--matter-dark-blue);
    }
    a {
      color: var(--matter-bright-blue-dark);
      text-decoration: underline;
      &:hover {
        color: var(--matter-dark-blue);
        text-decoration: underline;
      }
    }
    input[type='checkbox'] {
      accent-color: var(--matter-bright-blue-dark);
    }
  }

  #ps-group-signup-page-contract-group,
  #ps-group-measure-choice-terms-conditions-sandbox {
    div {
      color: white;
    }
    a {
      color: var(--matter-bright-blue);
      text-decoration: underline;
      &:hover {
        color: var(--matter-bright-blue-light);
        text-decoration: underline;
      }
    }
    input[type='checkbox'] {
      accent-color: var(--matter-bright-blue);
    }
  }
`;

export const Result = styled(ParticleResult)`
  flex-grow: 1;

  .matter-result-icon,
  .particle-result-icon {
    margin-bottom: 0;
  }
`;

export const SignUpViewResult = styled(ParticleResult)`
  flex-grow: 1;
  padding-top: 0;

  .matter-result-icon,
  .particle-result-icon {
    margin-bottom: 0;
  }
  div {
    color: white;
  }
`;

export const ResultIcon = styled.span`
  color: var(--matter-green-dark);
  font-size: 80px;
`;

export const SignUpViewResultIcon = styled(ResultIcon)`
  ${({ userType }) => userType && `
    color: ${userType === 'selfserve' ? 'var(--matter-dark-blue)' : 'var(--matter-bright-blue-light)'};
  `}
`;

export const H2 = styled.h2`
  margin-top: 0px;
  margin-bottom: 22px;
  font-weight: 400;
  color: var(--gray-9);
  font-size: 14px;
`;

export const PSToSContainer = styled.div`
  min-height: ${({ numLines }) => 25 + numLines * 23 + 'px'};
`;

export const FormSubmitContainer = styled.div`
  min-height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ErrorContainer = styled.span`
  margin-top: 50px;
`;

export const Footer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 30px;
  a {
    margin-left: 5px;
  }
`;

export const SignUpViewFooter = styled(Footer)`
  color: white;
`;

export const FooterMessage = styled.div`
  padding-bottom: 10px
`

export const SignUpViewLink = styled.a`
  color: var(--matter-bright-blue);
  text-decoration: underline;
  &:hover {
    color: var(--matter-bright-blue-light);
    text-decoration: underline;
  }
`;

