import { INITIAL_STATE } from './initialState';

const activationReducer = (
  state = INITIAL_STATE,
  { type, error, errorSummary, loading }
) => {
  switch (type) {
    case 'USER_ACTIVATION_TOKEN_STARTED':
      return {
        ...state,
        error: null,
        errorSummary: null,
        loading: true
      };
    case 'USER_ACTIVATION_TOKEN_FAILURE':
      return {
        ...state,
        error: error,
        errorSummary: errorSummary,
        loading: false
      };
    case 'USER_ACTIVATION_OTHER_FAILURE':
      return {
        ...state,
        error: error,
        errorSummary: errorSummary,
        loading: false
      };
    case 'USER_ACTIVATION_VALIDATION_FAILURE':
      return {
        ...state,
        error: error,
        errorSummary: errorSummary,
        loading: false
      };
    default:
      return state;
  }
};

export default activationReducer;
