import Spin from "@qc/particle/lib/components/spin"
import React, { useEffect, useState } from "react"
import makeAsyncScriptLoader from "react-async-script"

const CaptchaProtectedComponentBase = ({action, onPass, onFail, grecaptcha}) => {
    const [hasCaptchaPassed, setHasCaptchaPassed] = useState(undefined)
    const [showCaptchaCheckbox, setShowCaptchaCheckbox] = useState(false)
    const captchaEndpoint = '/users/api/verify/recaptcha';

    function log(msg) {
        if(window.location.host === 'test.quantcast.com' || window.location.host === 'localhost:3000') {
          console.log("%c🔑 " + msg, "color:orange");
        }
    }

    const executeCaptchaV3 = () => {
        grecaptcha.execute(process.env.REACT_APP_CAPTCHA_SITE_KEY_V3 , { action })
        .then(token => {
            log("Recaptcha returned V3 token " + token)
            return fetch(captchaEndpoint, {
                method: 'POST',
                body: JSON.stringify({
                  version: "v3",
                  token: token
                  })
              }).then((resp) => {
                if(resp.status === 200) {
                    onPass('V3', token)
                    setHasCaptchaPassed('V3', true)
                } else {
                    log("Recaptcha returned V3 token failed validation. asking for V2 support.")
                    executeCaptchaV2()
                }
             });
        }).catch(error => {
            log("Recaptcha returned V3 token generation failed. asking for V2 support.")
            executeCaptchaV2()
        })
    }

    const executeCaptchaV2 = () => {
        setShowCaptchaCheckbox(true)
        grecaptcha.render("reCaptchaV2", {
            'sitekey' : process.env.REACT_APP_CAPTCHA_SITE_KEY_V2,
            'expired-callback': () => {
                log("Recaptcha returned V2 failed")
                onFail()
                setHasCaptchaPassed(false)
            },
            'callback': (token) => {
                log("Recaptcha returned V2 token " + token)
                fetch(captchaEndpoint, {
                    method: 'POST',
                    body: JSON.stringify({
                      version: "v2",
                      token: token
                      })
                  }).then((resp) => {
                    if(resp.status === 200) {
                        onPass('V2', token)
                        setHasCaptchaPassed(true)
                    } else {
                        log("Recaptcha returned V2 token failed validation.")
                        onFail()
                        setHasCaptchaPassed(false)
                    }
                 }).catch(() => {
                    onFail()
                    setHasCaptchaPassed(false)
                 })
                
            }
        });
    }

    useEffect(() => {
        
        if (grecaptcha) {
            grecaptcha.ready(executeCaptchaV3)
        }
    }, [grecaptcha])

    // _DATADOG_SYNTHETICS_BROWSER is set by the bots on datadog.
    // When this happens we still hit the endpoint, for the IP to get checked.
    // The reason is that we have a problem on the recaptcha code not triggering
    // onload event on the tests.
    if (window._DATADOG_SYNTHETICS_BROWSER !== undefined && !hasCaptchaPassed) {
        onPass('V3', "datadog_integration")
        setHasCaptchaPassed(true)
    }

    if (!hasCaptchaPassed) {
        return (
            <div 
                id="reCaptchaV2"
                className="g-recaptcha"
                data-expired-callback="recaptchaExpired"
                data-sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
            >
                {!showCaptchaCheckbox ? <Spin size="large" style={{ color: 'var(--matter-bright-blue-dark)' }} /> : null}
            </div>
        )
    }
    return null
}

const CaptchaProtectedComponent = makeAsyncScriptLoader(`https://google.com/recaptcha/api.js?onload=onloadcallback&render=${process.env.REACT_APP_CAPTCHA_SITE_KEY_V3}`, {
    callbackName: 'onloadcallback',
    globalName: 'grecaptcha'
})(CaptchaProtectedComponentBase)

export default CaptchaProtectedComponent