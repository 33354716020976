import { handleResponse } from '../../helpers/handleResponse'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const fetching = () => ({
  type: 'FETCHING_PROFILE'
});

export const changingPassword = () => ({
  type: 'CHANGING_PASSWORD'
});

export const savingProfile = () => ({
  type: 'SAVING_PROFILE'
});

export const useProfileActions = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return useMemo(() => ({
   fetchProfile: () => {
      dispatch(fetching());
      fetch('/users/api/profile', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(handleResponse(history))
        .then(resp => {
          dispatch({
            type: 'FETCH_PROFILE_SUCCESS',
            idpProvided: resp.idpProvided,
            profile: {
              email: resp.email,
              firstName: resp.firstName,
              lastName: resp.lastName,
              timezone: resp.timezone
            }
          })
        })
        .catch(error => {
          dispatch({
            type: 'FETCH_PROFILE_FAILURE',
            error
          })
        });
    },

    saveProfile: (profileChanges, profile) => {
      dispatch(savingProfile());
      return fetch('/users/api/profile', {
        method: 'POST',
        body: JSON.stringify(profileChanges)
      })
        .then(handleResponse(history))
        .then(() => 
          dispatch({
            type: 'SAVE_PROFILE_SUCCESS',
            profile: profile
          })
        )
        .catch(error => {
          dispatch({
            type: 'SAVE_PROFILE_FAILURE',
            error
          })
        })
    },

    changePassword: (oldPassword, newPassword) => {
      dispatch(changingPassword());
      return fetch('/users/api/profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({oldPassword, newPassword})
      })
        .then(handleResponse(history))
        .then(() => {
          dispatch({
            type: 'CHANGE_PASSWORD_SUCCESS'
          });
        })
        .catch(error => {
          dispatch({
            type: 'CHANGE_PASSWORD_FAILURE',
            error
          });
        });
    },
  }), [dispatch, history]);
}