import { Typography, ConfigProvider } from 'antd';
import React from "react"
import QCPanelContainer from "../components/QCPanelContainer"
import ProductTierOption from "../components/ProductTierOption"
import * as Stld from "./ProductMatrix.styles"
import { ROUTES } from "../constants/routes"
import { ENTERPRISE_TIER_CONTENT, MAX_TIER_CONTENT, PRO_TIER_CONTENT } from "../constants/signup";
import { checkLoggedInAndRedirect } from "../utils/loggedInCheckRedirect";

export const ProductMatrix = () => {
    checkLoggedInAndRedirect();

    return (
        <ConfigProvider prefixCls="matter">
            <QCPanelContainer type="secondary">
                <Stld.Content>
                    <Stld.QuantcastLogo onClick={() => {window.location.href = ROUTES.HOME_PAGE}} />
                    <Stld.SubHeader>Choose a tier for your organization</Stld.SubHeader>
                    <Stld.ProductContent>
                        <ProductTierOption {...MAX_TIER_CONTENT} />
                        <ProductTierOption {...PRO_TIER_CONTENT} />
                        <ProductTierOption {...ENTERPRISE_TIER_CONTENT} />
                    </Stld.ProductContent>
                    <Stld.LinkSection>
                        <div>
                            Have an account? <Typography.Link href="/selfserve/login">Log In</Typography.Link>
                        </div>
                        <div>
                            Quantcast sales team?{' '}
                            <Typography.Link href={ROUTES.SELFSERVE_SIGNUP + "?orgtype=sales_prospecting&product_tier=max"}>
                                Create a Sales Account
                            </Typography.Link>
                        </div>
                    </Stld.LinkSection>
                </Stld.Content>
            </QCPanelContainer>
        </ConfigProvider>
    )
}