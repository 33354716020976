import React, { useState, useCallback } from "react"
import { Button, ConfigProvider, Form, Input, Typography, Select } from 'antd'
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk'

import { connect } from "react-redux";

import { getForwardUrl } from "../oktaConfigUtil"
import { getValidRedirect } from '../utils/whiteListRedirect'
import * as Stld from "./SignUpView.styles";
import { registerUser } from "../store/reducers/user_registration/actions"
import CaptchaProtectedComponent from "../components/CaptchaProtectedComponent"
import QCPanelContainer from "../components/QCPanelContainer";
import COUNTRIES from '../constants/countries.json'
import { ORG_TYPES, PRODUCT_TIERS } from "../constants/signup";
import PinnedCard from "../components/PinnedCard"
import { checkLoggedInAndRedirect } from "../utils/loggedInCheckRedirect"
import { ROUTES } from "../constants/routes";

const successMessageView = (success, idpInitiated, email) => {
  if(!success) {
    return null;
  }
  if (idpInitiated) {
    return (
      <Stld.Result
        status="success"
        title="Ready to go!"
        icon={<Stld.ResultIcon className="material-symbols-outlined">check_circle</Stld.ResultIcon>}
        subTitle="Your email is configured to an external identity provider. You can directly login with your email."
        extra={[<Button type="primary" href="/user/login">Go to Login</Button>]} />
    )
  }

  return (
    <Stld.Result
      title="Check your inbox"
      icon={<Stld.SignUpViewResultIcon userType="selfserve" className="material-symbols-outlined">mail</Stld.SignUpViewResultIcon>}
      subTitle={
        <>
          <div id="signupSuccessCard">
            <p>
              <span>
                Check <strong>{email}</strong> for an activation email to activate your account.
              </span>
            </p>
            <p>
              <Button 
                type="primary"
                style={{marginTop: '20px'}}
                onClick={() => window.location.href='/selfserve/login?login_hint=' + encodeURIComponent(email)}
              >
                Back to Login
              </Button>
            </p>
          </div>
        </>
      }
    />
  )
}

export const MarketerSignUpViewBase = ({
  saving,
  error,
  success,
  idpInitiated,
  registerUser,
  testMode
}) => {
  const urlParams = new URLSearchParams(location.search);
  const orgType = (urlParams.get('orgtype') || '').toLowerCase();
  let productTier = (urlParams.get('product_tier') || '').toLowerCase();
  const validOrgTypes = Object.values(ORG_TYPES);
  const validProductTiers = Object.values(PRODUCT_TIERS);

  if (validOrgTypes.indexOf(orgType) < 0 || validProductTiers.indexOf(productTier) < 0) {
    window.location = window.origin || "https://www.quantcast.com";
    return;
  }
  const isSalesProspecting = (orgType === ORG_TYPES.SALES_PROSPECTING)
  if (isSalesProspecting) {
    productTier = PRODUCT_TIERS.MAX;
  }

  const [form] = Form.useForm()
  const forwardUrl = getForwardUrl()
  const redirectUrl = getValidRedirect(forwardUrl);
  const [hasCaptchaPassed, setHasCaptchaPassed] = useState(false)
  const [recaptchaToken, setRecatpchaToken] = useState(null)
  const [recaptchaVersion, setRecatpchaVersion] = useState(null)
  const [signed, setSigned] = useState(testMode ? true : false)
  const [formValid, setFormValid] = useState(false)
  const [pactSafeError, setPactSafeError] = useState(null)

  checkLoggedInAndRedirect();
  const psGroupKey = process.env.REACT_APP_PACT_SAFE_ADVERTISE_GROUP_KEY;

  const _registerUser = (formData) => {
    if (typeof window._ps === 'function') {
      window._ps((psGroupKey || 'advertise-signup-terms') + ':send', 'agreed', {
        disable_sending: false, // We have to revert to allow sending with the snippet here.
        event_callback: (err, eventType, group, request) => {
          if (err) {
            setPactSafeError("error");
            return false;
          }

          registerUser(
            formData.email,
            formData.firstName,
            formData.lastName,
            redirectUrl,
            recaptchaVersion,
            recaptchaToken,
            null,
            JSON.stringify({
              orgName: isSalesProspecting ? `${formData.firstName} ${formData.lastName} Sales Prospecting` : formData.orgName,
              orgType: orgType,
              productTier: productTier,
              country: formData.country,
            })
          );
          return true;
        }
      });
  } else {
    registerUser(
      formData.email,
      formData.firstName,
      formData.lastName,
      redirectUrl,
      recaptchaVersion,
      recaptchaToken,
      null,
      JSON.stringify({
        orgName: isSalesProspecting ? `${formData.firstName} ${formData.lastName} Sales Prospecting` : formData.orgName,
        orgType: orgType,
        productTier: productTier,
        country: formData.country,
      })
    );
  }
  };

  const onPass = useCallback((version, token) => {
    setHasCaptchaPassed(true)
    setRecatpchaVersion(version)
    setRecatpchaToken(token)
  }, [setRecatpchaToken, setRecatpchaVersion])

  let errorMessage = null
  if (error === "403") {
    errorMessage = <Stld.Alert message="Sorry, there was a problem with recatcpha. Please reload and try again" type="error" showIcon data-cy="errorMessageTokenExpired" />
  } else if (error === "409") {
    errorMessage = <Stld.Alert message="Sorry, this email is already registered." type="warning" showIcon data-cy="errorMessageTokenExpired" />
  } else if (error === "417") {
    errorMessage = <Stld.Alert message="This email address is blocked." type="error" showIcon />
  } else if (pactSafeError) {
    errorMessage = <Stld.Alert message="Couldn't sign the terms of service. Please try again later." type="error" showIcon />
  } else if (error) {
    errorMessage = <Stld.Alert message="An error occurred when trying to activate your account. Please try again later." type="error" showIcon />
  }

  const successMessage = successMessageView(success, idpInitiated, form.getFieldValue('email'))

  // Turns out it's impossible to check whether or not an antd form is valid or not, is this okay for now?
  const SALES_PROSPECTING_EMAIL_REGEX = /^[A-Za-z0-9._%+-]+\+[A-Za-z0-9._%+-]+@quantcast\.com$/ // Forcing subaddressing
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/

  const updateFormValidity = (updatedValues, allValues) => {
    if (allValues.firstName?.length > 1 && 
      allValues.lastName?.length > 1 && 
      (
        (isSalesProspecting && SALES_PROSPECTING_EMAIL_REGEX.test(allValues.email)) || 
        (!isSalesProspecting && EMAIL_REGEX.test(allValues.email) && allValues?.orgName?.length > 2)
      )) {
        setFormValid(true)
      } else {
        setFormValid(false)
      }
  }
  const isSignUpDisabled = saving || !formValid || !(isSalesProspecting || signed)

  return (
    <ConfigProvider prefixCls="matter">
      <QCPanelContainer type="secondary" showImage>
        <div style={{ padding: '0 50px' }}>
          <PinnedCard theme="light">
            <Stld.Content>
              <Stld.QuantcastLogoContainer>
                <Stld.QuantcastLogo onClick={() => {window.location.href = ROUTES.HOME_PAGE}} />
              </Stld.QuantcastLogoContainer>
              {!successMessage ? (
                  <div data-cy="header" style={{ textAlign: 'center' }}>
                    {/*!isSalesProspecting && <Stld.H2>You have chosen the <strong>{productTier[0].toUpperCase() + productTier.slice(1)}</strong> tier</Stld.H2>*/}
                  </div>
                ) : null}
              {errorMessage}
              {successMessage ? successMessage :
                <>
                  <Stld.Form
                    requiredMark={false}
                    form={form}
                    layout="vertical"
                    onFinish={_registerUser}
                    onValuesChange={updateFormValidity}
                  >
                    <div style={{width: "100%", display:"flex", gap: "10px"}}>
                      <Stld.FormControl
                        style={{width: "50%"}}
                        data-cy="firstNameControl"
                        label="First Name"
                        name="firstName"
                        rules={[
                          {required: true, message: 'Please input your first name' },
                          {whitespace: true, message: 'First name cannot be a whitespace'},
                          {min: 2, message: "Names must have a minimum of two characters"}
                        ]}
                      >
                        <Input data-testid="firstNameInput" placeholder="Your first name" />
                      </Stld.FormControl>
                      <Stld.FormControl
                        style={{width: "50%"}}
                        data-cy="lastNameControl"
                        label="Last Name"
                        name="lastName"
                        rules={[
                          {required: true, message: 'Please input your last name' },
                          {whitespace: true, message: 'First name cannot be a whitespace'},
                          {min: 2, message: "Names must have a minimum of two characters"}
                        ]}
                      >
                        <Input data-testid="lastNameInput" placeholder="Your last name" />
                      </Stld.FormControl>
                    </div>
                    <Stld.FormControl
                      data-cy="emailAddressControl"
                      label={isSalesProspecting ? "Quantcast Email" : "Business Email"}
                      name="email"
                      validateTrigger="onBlur"
                      rules={[
                        {required: true, message: 'Email address is not valid' },
                        (isSalesProspecting ? 
                          {
                            pattern: new RegExp(SALES_PROSPECTING_EMAIL_REGEX), 
                            message: "Use a subaddressed Quantcast email (e.g. john.doe+sales@quantcast.com)",
                          } : {
                            pattern: new RegExp(EMAIL_REGEX),
                            message: 'Business email address is not valid. Please check for any invalid characters such as spaces.'
                          }
                        )
                      ]}
                    >
                      <Input whitespace="true" data-testid="emailInput" id="emailInput" type="email" placeholder="Your business email" />
                    </Stld.FormControl>

                    { !isSalesProspecting ?
                      <div style={{ marginBottom: '24px'  }}>
                        <Stld.FormControl
                          data-cy="orgNameControl"
                          label="Organization Name"
                          name="orgName"
                          validateTrigger="onBlur"
                          style={{ marginBottom: '2px' }}
                          rules={[
                            { required: true, message: 'Please input your organization name' },
                            { whitespace: true, message: 'Name can not be blank' },
                            { min: 3, message: "Organization name must be 3 or more characters long "}
                          ]}
                        >
                          <Input data-testid="orgNameInput" id="orgNameInput" placeholder="Your organization name" />
                        </Stld.FormControl>
                        <span style={{ color: 'var(--gray-7)'}} >You can’t change your organization name later</span>
                      </div>
                      : null
                    }

                    { !isSalesProspecting ?
                      <div style={{ marginBottom: '24px'  }}>
                        <Stld.FormControl
                          data-cy="countryControl"
                          label="Billing Country"
                          name="country"
                          validateTrigger="onBlur"
                          rules={[
                            { required: true, message: 'Please select the country where your business resides' }
                          ]}
                          style={{ marginBottom: '2px' }}
                        >
                          <Select
                            showSearch
                            data-testid="countryInput" 
                            id="countryInput" 
                            placeholder="Your business's billing country"
                            options={COUNTRIES.map((c) => { return { 'value': c.code, 'label': c.text } })}
                            filterOption={(inputValue, option) =>
                              option.label.toLowerCase().includes(inputValue.toLowerCase())
                            }
                          />
                        </Stld.FormControl>
                      </div>
                      : null
                    }

                    {!isSalesProspecting ?
                      <Stld.PSToSContainer numLines={1}>
                        <PSClickWrap
                          allowDisagreed={true}
                          disableSending={true}
                          clickWrapStyle="combined"
                          accessId={process.env.REACT_APP_PACT_SAFE_ACCESS_ID || ''}
                          groupKey={process.env.REACT_APP_PACT_SAFE_ADVERTISE_GROUP_KEY || ''}
                          signerIdSelector={"emailInput"}
                          onChecked={() => setSigned(true)}
                          onUnchecked={() => setSigned(false)}
                        />
                      </Stld.PSToSContainer>
                      :
                      null
                    }
                    <Stld.FormSubmitContainer>
                      {hasCaptchaPassed ?
                        <Stld.FormControl style={{margin: 0, width: "60%"}}>
                          <Button data-testid="submit" type="primary" htmlType="submit" loading={saving} disabled={isSignUpDisabled}>
                            Sign Up
                          </Button>
                        </Stld.FormControl>:
                        <CaptchaProtectedComponent action="register" onPass={onPass} onFail={() => console.log("Captcha Failed")} />
                      }
                    </Stld.FormSubmitContainer>
                  </Stld.Form>
                  <Stld.Footer>
                    <Stld.FooterMessage>Have an account? <Typography.Link href="/selfserve/login">Log In</Typography.Link></Stld.FooterMessage>
                    <Stld.FooterMessage>Not an advertiser? <Typography.Link href="/signin/register">Sign up as a Publisher</Typography.Link></Stld.FooterMessage>
                  </Stld.Footer>
                </>
              }
            </Stld.Content>
          </PinnedCard>
        </div>
      </QCPanelContainer>
    </ConfigProvider>
  );
};

const mapStateToProps = state => ({
  saving: state.registration.loading,
  registration: state.registration,

  error: state.registration.error,
  success: state.registration.success,
  idpInitiated: state.registration.idpInitiated
})

const mapDispatchToProps = dispatch => ({
  registerUser: (email, firstName, lastName, redirectUrl, recaptchaVersion, recaptchaToken, password, signUpAttributes) =>
    dispatch(registerUser('MARKETER', email, firstName, lastName, redirectUrl, recaptchaVersion, recaptchaToken, password, signUpAttributes)),
})

const MarketerSignUpView = connect(mapStateToProps, mapDispatchToProps)(MarketerSignUpViewBase)

export default MarketerSignUpView
