import React, { useState, useCallback } from "react"
import { Button, ConfigProvider, Form, Input } from 'antd'
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk'

import { connect } from "react-redux";

import { getForwardUrl } from "../oktaConfigUtil"
import { getValidRedirect } from '../utils/whiteListRedirect'
import * as Stld from "./SignUpView.styles";
import { registerUser } from "../store/reducers/user_registration/actions"
import CaptchaProtectedComponent from "../components/CaptchaProtectedComponent"
import QCPanelContainer from "../components/QCPanelContainer";
import PinnedCard from "../components/PinnedCard"
import { checkLoggedInAndRedirect } from "../utils/loggedInCheckRedirect"

const successMessageView = (success, idpInitiated) => {
  if(!success) {
    return null;
  }
  if (idpInitiated) {
    return (
      <Stld.SignUpViewResult
        title="Ready to go!"
        icon={
          <Stld.SignUpViewResultIcon userType="publisher" className="material-symbols-outlined">
            check_circle
          </Stld.SignUpViewResultIcon>
        }
        subTitle="Your email is configured to an external identity provider. You can directly login with your email."
        extra={[
          <Button type="primary" href="/user/login">
            Go to Login
          </Button>,
        ]}
      />
    )
  }

  return (
    <Stld.SignUpViewResult
      title="Check your inbox"
      icon={
        <Stld.SignUpViewResultIcon userType="publisher" className="material-symbols-outlined">
          mail
        </Stld.SignUpViewResultIcon>
      }
      subTitle="We have sent you an email to activate this account. Please check your inbox for the invitation."
      extra={[
        <Button type="primary" href="/user/login">
          Back to Login
        </Button>,
      ]}
    />
  )
}

export const SignUpViewBase = ({
  saving,
  error,
  success,
  idpInitiated,
  registerUser,
  testMode
}) => {
  const urlParams = new URLSearchParams(location.search);
  const loginHint = urlParams.get('login_hint');
  const [form] = Form.useForm();
  const forwardUrl = getForwardUrl();
  const redirectUrl = getValidRedirect(forwardUrl);
  const [hasCaptchaPassed, setHasCaptchaPassed] = useState(false)
  const [recaptchaToken, setRecatpchaToken] = useState(null)
  const [recaptchaVersion, setRecatpchaVersion] = useState(null)
  const [signed, setSigned] = useState(testMode ? true : false)

  checkLoggedInAndRedirect();

  const _registerUser = (formData) => {
    registerUser(
      formData.email,
      formData.firstName,
      formData.lastName,
      redirectUrl,
      recaptchaVersion,
      recaptchaToken
    );
  };

  const onPass = useCallback((version, token) => {
    setHasCaptchaPassed(true)
    setRecatpchaVersion(version)
    setRecatpchaToken(token)
  }, [setRecatpchaToken, setRecatpchaVersion])

  let errorMessage = null
  if (error === "403") {
    errorMessage = <Stld.Alert message="Sorry, there was a problem with recatcpha. Please reload and try again" type="error" showIcon data-cy="errorMessageTokenExpired" />
  } else if (error === "409") {
    errorMessage = <Stld.Alert message="Sorry, this email is already taken. Please try another one." type="warning" showIcon data-cy="errorMessageTokenExpired" />
  } else if (error === "417") {
    errorMessage = <Stld.Alert message="This email address is blocked." type="error" showIcon />
  } else if (error) {
    errorMessage = <Stld.Alert message="An error occurred when trying to activate your account. Please try again later." type="error" showIcon />
  }

  const successMessage = successMessageView(success, idpInitiated)
  
  return (
    <ConfigProvider prefixCls="matter">
      <QCPanelContainer showImage type="primary">
        <div style={{ padding: '0 50px' }}>
          <PinnedCard theme="dark">
            <Stld.Content>
              <Stld.QuantcastLogoContainer>
                <Stld.SignUpViewQuantcastLogo onClick={() => {window.location.href = ROUTES.HOME_PAGE}} />
              </Stld.QuantcastLogoContainer>
              {errorMessage}
              {successMessage ? successMessage : 
                <>
                  <Stld.PublisherIndication>
                    For Publishers
                  </Stld.PublisherIndication>
                  <Stld.Form
                    requiredMark={false}
                    form={form}
                    layout="vertical"
                    onFinish={_registerUser}
                  >
                    <Stld.FormControl
                      textType="primary"
                      data-cy="emailAddressControl"
                      value={loginHint}
                      label="Email"
                      name="email"
                      validateTrigger="onBlur"
                      rules={[
                        {required: true, message: 'Email address is not valid' },
                        {type: "email", message: 'Email address is not valid'}
                      ]}
                    >
                      <Input data-testid="emailInput" id="emailInput" placeholder="Your email address" />
                    </Stld.FormControl>

                    <Stld.FormControl
                      textType="primary"
                      data-cy="firstNameControl"
                      name="firstName"
                      label="First Name"
                      rules={[
                        {required: true, message: 'Please input your first name' },
                        {whitespace: true, message: 'First name cannot be a whitespace'}
                      ]}
                    >
                      <Input data-testid="firstNameInput" placeholder="Your first name" />
                    </Stld.FormControl>
                    <Stld.FormControl
                      textType="primary"
                      data-cy="lastNameControl"
                      name="lastName"
                      label="Last Name"
                      rules={[
                        {required: true, message: 'Please input your last name' },
                        {whitespace: true, message: 'First name cannot be a whitespace'}
                      ]}
                    >
                      <Input data-testid="lastNameInput" placeholder="Your last name" />
                    </Stld.FormControl>

                    <Stld.PSToSContainer numLines={2}>
                      <PSClickWrap
                        allowDisagreed={true}
                        clickWrapStyle="combined"
                        accessId={process.env.REACT_APP_PACT_SAFE_ACCESS_ID || ''}
                        groupKey={process.env.REACT_APP_PACT_SAFE_GROUP_KEY || ''}
                        signerIdSelector={"emailInput"}
                        onValid={() => setSigned(true)}
                        onInvalid={() => setSigned(false)}
                        onUnchecked={() => setSigned(false)}
                      />
                    </Stld.PSToSContainer>
                    <Stld.FormSubmitContainer>
                      {hasCaptchaPassed ? 
                        <Stld.FormControl style={{margin: 0, width: "60%"}}>
                          <Button data-testid="submit" type="primary" htmlType="submit" loading={saving} disabled={saving || !signed}>
                            Sign up
                          </Button>
                        </Stld.FormControl> :
                        <CaptchaProtectedComponent action="register" onPass={onPass} onFail={() => console.log("Captcha Failed")} />
                      }
                    </Stld.FormSubmitContainer>
                  </Stld.Form>
                  <Stld.SignUpViewFooter>
                    <Stld.FooterMessage>Have an account? <Stld.SignUpViewLink href="/user/login">Log In</Stld.SignUpViewLink></Stld.FooterMessage>
                    <Stld.FooterMessage>Not a publisher? <Stld.SignUpViewLink href="/selfserve/register?orgtype=tiered_self_serve&product_tier=pro">Sign up as an Advertiser</Stld.SignUpViewLink></Stld.FooterMessage>
                  </Stld.SignUpViewFooter>
                </>
              }
            </Stld.Content>
          </PinnedCard>
        </div>
      </QCPanelContainer>
    </ConfigProvider>
  );
};

const mapStateToProps = state => ({
  saving: state.registration.loading,
  registration: state.registration,
  error: state.registration.error,
  success: state.registration.success,
  idpInitiated: state.registration.idpInitiated
})

const mapDispatchToProps = dispatch => ({
  registerUser: (email, firstName, lastName, redirectUrl, recaptchaVersion, recaptchaToken) =>
    dispatch(registerUser('PUBLISHER', email, firstName, lastName, redirectUrl, recaptchaVersion, recaptchaToken))
})

const SignupView = connect(mapStateToProps, mapDispatchToProps)(SignUpViewBase)

export default SignupView
