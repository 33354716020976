import { handleResponse } from '../../helpers/handleResponse'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const useCredentialsActions = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  return useMemo(() => ({
      fetchCredentials: () => {
          dispatch({type: 'FETCHING_CREDENTIALS'});

          fetch('/ads/apiv2/manager/enabled-features')
          .then(handleResponse(history))
          .then(resp => {
              if (resp.includes('USER_PROFILE_CLIENT_CREDENTIALS_MANAGEMENT')) {
                  dispatch({
                      type: 'FETCH_FEATURE_FLAGS_SUCCESS',
                      featureEnabled: true,
                  });
                  return fetch('/users/credentials');
              } else {
                dispatch({
                    type: 'FETCH_FEATURE_FLAGS_SUCCESS',
                    featureEnabled: false,
                });
                dispatch({
                    type: 'FETCH_CREDENTIALS_SUCCESS',
                    credentials: [],
                });
              }
          }).then(handleResponse(history))
          .then(resp => {
              dispatch({
                  type: 'FETCH_CREDENTIALS_SUCCESS',
                  credentials: resp,
              });
          }).catch(error => {
              dispatch({
                  type: 'FETCH_CREDENTIALS_FAILURE',
                  error,
              })
          });
      },

      createCredentials: () => {
          dispatch({type: 'FETCHING_CREDENTIALS'});
          fetch('/users/credentials', {
              method: 'POST',
          }).then(handleResponse(history))
          .then(resp => {
              dispatch({
                  type: 'CREATE_CREDENTIALS_SUCCESS',
                  newCredential: resp,
              });
          }).catch(error => {
              dispatch({
                  type: 'CREATE_CREDENTIALS_FAILURE',
                  error,
              });
          });
      },

      revokeCredentials: (key) => {
          dispatch({type: 'FETCHING_CREDENTIALS'});
          fetch(`/users/credentials/${key}`, {
              method: 'DELETE'
          }).then(handleResponse(history))
          .then(resp => {
              dispatch({
                  type: 'REVOKE_CREDENTIALS_SUCCESS',
                  key,
              })
          }).catch(error => {
              dispatch({
                  type: 'REVOKE_CREDENTIALS_FAILURE',
                  error,
              });
          });
      },
  }), [dispatch, history]);
};
