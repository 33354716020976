import { Card as ParticleCard, Form as ParticleForm } from 'antd'

import styled from 'styled-components';

export const QuantcastLogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`;

export const QuantcastLogo = styled.div`
  mask-position: center;
  mask-size: contain;
  mask-image: url("https://static.quantcast.com/images/quantcast-logo-2024.svg");
  mask-repeat: no-repeat;
  background-color: var(--matter-dark-blue);
  height: 36px;
  width: 235px;
  margin-bottom: 20px;
  cursor: pointer;
`;

export const FilledValue = styled.span`
  font-weight: 400;
  font-size: 16px;
`
export const FormControl = styled(ParticleForm.Item)`
  margin-bottom: 15px;

  .progress-spinner__root {
    width: 20px;
    height: 20px;
  }
  .matter-btn {
    width: 100%;
  }
  ul {
    padding: 5px;
  }
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Card = styled(ParticleCard)`
  background-color: var(--matter-white);
  width: 600px;
  padding: 20px 42px 20px;

  .matter-card-body {
    padding: 0px;
  }

  .simple-card__content {
    padding: 30px 20px 30px 20px;
  }

  .matter-form-item-label {
    padding-bottom: 4px;
  }

  .matter-form-item-label > label {
    color: var(--matter-gray-8);
    font-weight: 600;
  }
`;

export const H3 = styled.h3`
    margin-bottom: 22px;
    margin-top: 10px;
    font-weight: 600;
    color: #5e5e5e;
    font-size: 20px;
`;

export const CheckMark = styled.span`
  color: var(--green-6);
  height: 8p;
  margin-right: 8px;
  width: 8px;
`;

export const ChangesSavedLabel = styled.div`
  align-items: center;
  color: var(--green-6);
  display: flex;
  margin-right: 10px;
  font-size: 14px;
`;

export const ErrorMessageApi = styled.div`
  width: 314px;
  margin-top: 10px;
  p{color: var(--red-6)};
`;

export const FooterHolder = styled.footer`
    background: none repeat scroll 0 0 transparent;
    text-align: center;
    width: 100%;
    height: 20px;
    padding-top: 25px;
  `;

export const Footer = styled.div`
    margin: auto;
    a {
      color: var(--gray-13);
      font-size: 12px;
      font-weight: unset;
    }
  `;

export const FooterLink = styled.span`
    margin: 1em;    
  `;
