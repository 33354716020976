import { ROUTES } from '../../constants/routes';

export const handleResponse = history => (response) => {
  return response.text().then(text => {
    if(text === "Invalid token") {
     history.push(`${ROUTES.USER_LOGIN}?qcRefer=${window.location.pathname}`)
    }
    
    const data = text && JSON.parse(text)
    
    if (!response.ok) {
      if(response.status === 401) {

        return history.push(`${ROUTES.USER_LOGIN}?qcRefer=${window.location.pathname}`)
      }
      const error = {
        status: response.status,
        data
      }
      return Promise.reject(error);
    }
    return data;
  });
}