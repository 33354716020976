import { ROUTES } from "./routes"
import { enterpriseTierHandler } from "./tiers/enterprise"
import { maxTierHandler } from "./tiers/max"

export const ORG_TYPES = {
  TIERED_SELF_SERVE: "tiered_self_serve",
  SALES_PROSPECTING: "sales_prospecting",
  FREE_TRIAL: "free_trial",
}

export const PRODUCT_TIERS = {
  MAX: "max",
  PRO: "pro",
  ENTERPRISE: "enterprise",
}

export const MAX_TIER_CONTENT = {
  disabled: false,
  icon: 'person',
  tierName: "Max",
  description: "Built by Marketers for Marketers. All the tools you’d expect and entirely self-serve. ",
  includedFeatures: ["Unparalleled ad insights", "Tag and understand your brands", "Omnichannel", "Multiple sub-brands"],
  missingFeatures: [],
  clickHandler: maxTierHandler,
  buttonText: "Join the Waitlist"
}

export const PRO_TIER_CONTENT = {
  disabled: false,
  icon: 'person_add',
  tierName: "Pro",
  description: "All the tools you’ve come to love with Max, but with some help from our top-rated services team.",
  includedFeatures: ["Everything in Max", "Professional Services team at-the-ready to help along the way", "Prepaid or Invoiced"],
  missingFeatures: [],
  clickHandler: () => window.location.assign(`${ROUTES.SELFSERVE_SIGNUP}?orgtype=tiered_self_serve&product_tier=pro`),
  buttonText: "Sign Up"
}

export const ENTERPRISE_TIER_CONTENT = {
  disabled: false,
  icon: 'home_work',
  tierName: "Enterprise",
  description: "Professional Services team at-the-ready to help along the way",
  includedFeatures: ["Bespoke products and services", "Pick and choose what works for your business"],
  missingFeatures: [],
  clickHandler: enterpriseTierHandler,
  buttonText: "Contact Us"
}
