import profileReducer from './profile/reducer';
import activationReducer from './user_activation/reducer'
import registrationReducer from './user_registration/reducer'
import clientCredentialsReducer from './clientcredentials/reducer'
import notificationPreferencesReducer from './notificationpreferences/reducer'

const reducers = {
  profile: profileReducer,
  activation: activationReducer,
  registration: registrationReducer,
  clientCredentials: clientCredentialsReducer,
  notificationPreferences: notificationPreferencesReducer,
};

export default reducers;
