
import { INITIAL_STATE } from './initialState';

const clientCredentialsReducer = (
    state = INITIAL_STATE,
    action
    ) => {
        switch (action.type) {
        case 'FETCHING_CREDENTIALS':
            return { 
                ...state,
                fetching: true,
                error: null,
                newCredential: null,
                credentialRevoked: false,
            };
        case 'FETCH_FEATURE_FLAGS_SUCCESS':
            const { featureEnabled } = action;

            return {
                ...state,
                featureEnabled,
            };
        case 'FETCH_CREDENTIALS_SUCCESS':
            const { credentials } = action;

            return {
                ...state,
                fetching: false,
                credentials,
            };
        case 'CREATE_CREDENTIALS_SUCCESS':
            const { newCredential } = action;

            return {
                ...state,
                newCredential: newCredential,
                fetching: false,
                credentials: [
                    ...state.credentials,
                    newCredential,
                ],
            };
        case 'CREATE_CREDENTIALS_FAILURE':
            const { error } = action;

            return {
                ...state,
                fetching: false,
                error,
            };
        case 'REVOKE_CREDENTIALS_SUCCESS':
            const { key } = action;

            return {
                ...state,
                fetching: false,
                credentialRevoked: true,
                credentials: state.credentials.filter(credential => credential['key'] !== key),
            };
        default:
            return state;
    }
};

export default clientCredentialsReducer;