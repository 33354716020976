
import Modal from "@qc/particle/lib/components/modal"
import Spin from "@qc/particle/lib/components/spin";
import './mkto.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/pro-light-svg-icons';

export function maxTierHandler() {
    const formModal = Modal.info({
      width: '40em',
      wrapClassName: "mktoFormContainer",
      title: (
        <div data-cy="header" style={{textAlign: "center"}}>
          <h3 style={{marginBottom: "0px"}} data-cy="qText">Join the Waitlist</h3>
          <span>You have selected the <b>Max</b> tier.</span>
        </div>
      ),
      okButtonProps: {
        style: {
          display: 'none'
        }
      },
      closable: true,
      icon: (<></>),
      content: (
        <>
          <Spin id="marketoSpinner" className="onFormLoadHide" size="large" style={{width: '100%'}} />
          <form id="mktoForm_4005" style={{marginTop: "25px"}}></form> 
          <div id="marketoAlreadyRegistered" className="onFormLoadDisplay">
            Have an account? <a href="/selfserve/login">Log In</a>
          </div>
        </>
      ),
    });

    document.querySelectorAll('.onFormLoadHide').forEach(e => e.classList && e.classList.remove('hidden'));
    document.querySelectorAll('.onFormLoadDisplay').forEach(e => e.classList && e.classList.add('hidden'));
    MktoForms2.loadForm("https://info.quantcast.com", "516-DGM-318", 4005, form => {
      document.querySelectorAll('.onFormLoadHide').forEach(e => e.classList && e.classList.add('hidden'));
      document.querySelectorAll('.onFormLoadDisplay').forEach(e => e.classList && e.classList.remove('hidden'));

      document.querySelectorAll('#mktoForm_4005 input').forEach(e => {
        e.classList.add('particle-input');
        e.classList.remove('mktoField');
        e.classList.remove('mktoTextField');
      });
      document.querySelectorAll('#mktoForm_4005 textarea').forEach(e => {
        e.classList.add('particle-input');
        e.classList.remove('mktoField');
      });
      document.querySelectorAll('#mktoForm_4005 label').forEach(e => {
        e.classList.remove('mktoLabel');
      });
      document.querySelectorAll('#mktoForm_4005 select').forEach(e => {
        e.classList.add('particle-input');
      });
      document.querySelectorAll('#mktoForm_4005 button[type=submit]').forEach(e => {
        e.classList.remove('mktoButton');
        e.classList.add('particle-btn');
        e.classList.add('particle-btn-primary');
      });

      form.onSuccess(function(values, followUpUrl) {
          formModal.destroy();

          Modal.success({
            closable: false,
            width: '40em',
            title: '',
            icon: (<></>),
            style: { padding: '5px' },
            onOk: () => window.location.href = "/",
            content: (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ paddingBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'left', alignItems: 'center' }}>
                  <div style={{ paddingRight: '15px' }}>
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      size='2x'
                      style={{ color: 'var(--green-6)' }}
                    />
                  </div>
                  <div style={{ fontSize: '24px', fontWeight: '600' }}>
                    Form Submitted!
                  </div>
                </div>
                <div style={{ paddingLeft: '5px', fontSize:'16px' }}>
                  <p style={{ marginBottom: '5px' }}>
                    Hi {values.FirstName},
                  </p>
                  <p>
                    Thank you for contacting Quantcast! A member of our team
                    will reach out to you shortly.
                  </p>
                </div>
              </div>
            )
          });

          return false;
      });
    });
  }
