export const INITIAL_STATE = {
    fetching: true,
    featureEnabled: false,
    error: null,

    newCredential: null,
    credentialRevoked: false,

    credentials: [],
};
  