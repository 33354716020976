import random from 'random-string-generator';
import { getValidRedirect } from './utils/whiteListRedirect'

const SECURITY_TOKEN_LENGTH = 10;
const LOGIN_FORWARD_PARAMETER_1 = 'forward';
const LOGIN_FORWARD_PARAMETER_2 = 'qcRefer';
const LOGIN_FORWARD_PARAMETER_3 = 'qcrefer';
const AUTHN_REDIRECT_URI = '/token/setQcToken';

export function getForwardUrl() {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(LOGIN_FORWARD_PARAMETER_1) || urlParams.get(LOGIN_FORWARD_PARAMETER_2) || urlParams.get(LOGIN_FORWARD_PARAMETER_3);
}

export function getStateToken() {
  //set security_token and redirect_uri
  let state = `security_token=${random(SECURITY_TOKEN_LENGTH)}&redirectUri=${getOktaRedirectUri()}`;

  const forwardUrl = getForwardUrl();
  
  if (forwardUrl) {
    const redirectUrl = getValidRedirect(forwardUrl);
    state += `&forwardUrl=${redirectUrl}`;
  }
  return state;
}

export function setStateToken(config) {
  //set security_token and redirect_uri
  config.authParams.state = getStateToken();
}

export function getOktaRedirectUri() {
  return getWindowOrigin() + AUTHN_REDIRECT_URI;
}

export function getWindowOrigin() {
  //Polyfill for IE
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }
  return window.location.origin;
}
