export const REQUIREMENTS = [
    'At least 8 characters',
    'At least 1 number',
    'At least 1 symbol',
    'At least 1 lowercase letter',
    'At least 1 uppercase letter',
    'No parts of your username'
];

export const executeRules = (username, newPassword) => {
    if (!newPassword) {
        return [null, null, null, null, null, null];
    }

    username = username || '';

    return [
        newPassword.match(/^.{8,}$/) !== null,
        newPassword.match(/[0-9]/) !== null,
        newPassword.match(/[\W\_]/) !== null,
        newPassword.match(/[a-z]/) !== null,
        newPassword.match(/[A-Z]/) !== null,
        validatePasswordVsUsername(username.replace(/[.][^.]*$/,'').toLowerCase(), newPassword.toLowerCase())
    ];
}

function validatePasswordVsUsername(trimmedName, password) {
    if (trimmedName === undefined || trimmedName === null  || trimmedName.length < 4) {
        return null
    } else {
        return (longestCommonSubsequence(trimmedName, password) < 4)
    }
}

function longestCommonSubsequence(s1, s2) {
    // string to array
    const arr1 = [...s1]
    const arr2 = [...s2]
  
    // define n x m sized array filled with 0's
    const matrix = Array(arr1.length + 1).fill().map(() => Array(arr2.length + 1).fill(0));
    let result = 0;
  
    // fill the matrix
    for(let i = 1; i <= arr1.length; i++) {
        for(let j = 1; j <= arr2.length; j++) {
            if (arr1[i-1] == arr2[j-1]) { 
                matrix[i][j] = matrix[i - 1][j - 1] + 1;
                if (matrix[i][j] > result) {
                    result = matrix[i][j];
                }
            }
            else {
                matrix[i][j] = 0;
            }
        }
    }
  
    return result;
  }
