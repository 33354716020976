export const checkLoggedInAndRedirect = () => {
    // To signin the new user we need an empty okta session. There fore 
    // if the user is logged in do not show this page.
    fetch('/users/cookie', {
        method: 'POST',
        mode: 'same-origin',
        cache: 'no-cache',
        credentials: 'same-origin',
    }).then(response => {
        if (response.ok) {
        response.json().then(res => {
            if (res.success === true) {
            window.location = window.origin || "https://www.quantcast.com";
            }
        });
        }
    });
}