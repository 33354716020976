import { getOktaRedirectUri, getForwardUrl } from "../oktaConfigUtil";

var myMemoryStore = {};
const storageProvider = {
  getItem: function(key) {
    // custom get
    return myMemoryStore[key];
  },
  setItem: function(key, val) {
    // custom set
    myMemoryStore[key] = val;
  },
  // optional
  removeItem: function(key) {
    delete myMemoryStore[key];
  }
}

export const oktaConfig = {
  // enable to "true" on signin widget ver >= 7.x to still revert to classic engine
  useClassicEngine: true,

  baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
  language: 'en',
  logo:
    'https://static.quantcast.com/images/quantcast-logo-2024.svg',
  colors: {
    brand: '#9FEDE4'
  },
  i18n: {
    en: {
      'primaryauth.title': 'Log In',
      'primaryauth.submit': 'Log In',
      'primaryauth.username.placeholder': 'Email Address',
      'primaryauth.username.tooltip': 'Email Address',
      'password.forgot.email.or.username.placeholder': 'Email Address',
      'password.forgot.email.or.username.tooltip': 'Email Address',
      'error.username.required': 'Please enter an email',
      'model.validation.field.username': 'Please check your email',
      'password.complexity.no_username': 'no parts of your email',
      'password.complexity.no_username.description': 'No parts of your email',
      'account.unlock.email.or.username.placeholder': 'Email Address',
      'account.unlock.email.or.username.tooltip': 'Email Address',
      'account.unlock.unlocked.desc': 'You can log in using your existing email and password.',
      'idx.recovery.completed': 'You can now sign in with your existing email and new password.',
      "password.reset.title.generic": "Set your password",
      "password.newPassword.placeholder": "Password",
      "password.confirmPassword.placeholder": "Repeat Password",
      "password.reset": "Set Password & Login",
      "errors.E0000004": "Incorrect username or password or the email is not verified by following instructions in the activation email."
    }
  },
  features: {
    // TODO: remove ver >= 7.x
    registration: true,

    // TODO: remove ver >= 7.x
    rememberMe: true,

    router: true,

    // TODO: remove ver >= 7.x
    // Instead, you can configure a routing rule with the application context.
    idpDiscovery: true,
  },
  routes: {
    '': 'defaultAuth',
    'signin': 'primaryAuth',
    'user/login': 'idpDiscovery',
    'signin/forgot-password': 'forgotPassword',
    'signin/reset-password': 'passwordReset',
    'signin/password-reset': 'passwordReset',
    'signin/password-expired': 'passwordExpired',
    'signin/custom-password-expired': 'customPasswordExpired',
    'signin/recovery-challenge': 'recoveryChallenge',
    'signin/recovery-emailed': 'recoveryEmailSent',
    'signin/recovery-question': 'recoveryQuestion',
    'signin/password-reset': 'passwordReset',
    'signin/reset-password/:token': 'recoveryLoading',
    'signin/recovery/:token': 'recoveryLoading',
    '*wildcard': 'defaultAuth',
  },
  idpDiscovery: {
    requestContext: `${window.location.origin}/user/login/callback?qcRefer=${getForwardUrl()}`
  },
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: getOktaRedirectUri(),
  authParams: {
    display: 'page',
    pkce: false,
    issuer: process.env.REACT_APP_OKTA_AUTH_SERVER,
    responseType: 'code',
    scopes: ['openid offline_access'],
    cookies: {
      secure: true
    },
    tokenManager: {
      storage: storageProvider,
    }, 
    transactionManager: {
      saveStateCookie: false,
      saveParamsCookie: false,
      saveNonceCookie: false,
      saveLastResponse: false,
    },    
    storageManager: {
      token: {
        storageType: 'localStorage',
        storageTypes: ['localStorage', 'memory']
      },
      transaction: {
        storageType: 'localStorage',
        storageTypes: ['localStorage', 'memory']
      },
      cache: {
        storageType: 'localStorage',
        storageTypes: ['localStorage', 'memory']
      },
      'legacy-oauth-params': {
        storageType: 'localStorage',
        storageTypes: ['localStorage', 'memory']
      }
    },
  }
};
