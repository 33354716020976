import styled from 'styled-components';

export const QuantcastLogoContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: flex-end;
`;

export const QuantcastLogo = styled.div`
  mask-position: center;
  mask-size: contain;
  mask-image: url("https://static.quantcast.com/images/quantcast-logo-2024.svg");
  mask-repeat: no-repeat;
  background-color: var(--matter-bright-blue);
  height: 36px;
  width: 235px;
  margin-bottom: 16px;
  cursor: pointer;
`;

export const OktaAuthContainer = styled.div`
  flex-grow: 1;
`;

export const BoxCaptcha = styled.div`
    background-color: #fff;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 hsla(0,0%,68.6%,.12);
    overflow: auto;
    border-radius: 3px;
    border-style: solid;
    border-width: 1px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    min-width: 300px;
`;