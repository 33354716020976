import React, { useState, useEffect, useReducer } from 'react';
import { Button, Form, Input, Typography, Row, Col, ConfigProvider } from 'antd'
import Space from '@qc/particle/lib/components/space'
import Spin from '@qc/particle/lib/components/spin'
import Result from '@qc/particle/lib/components/result'
import Table from '@qc/particle/lib/components/table'
import Modal from '@qc/particle/lib/components/modal'
import Checkbox from '@qc/particle/lib/components/checkbox'
import Select from '@qc/particle/lib/components/select'
import Tooltip from '@qc/particle/lib/components/tooltip'
import Switch from '@qc/particle/lib/components/switch'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt'
import { faExclamationCircle } from '@fortawesome/pro-light-svg-icons/faExclamationCircle'
import { faCopy } from '@fortawesome/pro-light-svg-icons/faCopy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import * as ProfileActions from '../store/reducers/profile/actions';
import * as ClientCredentialsActions from '../store/reducers/clientcredentials/actions'
import * as NotificationPreferencesActions from '../store/reducers/notificationpreferences/actions'
import * as Stld from './Profile.styles';
import { ROUTES } from '../constants/routes';
import PasswordInput, { PASSWORD_VALIDATOR } from '../components/PasswordInput';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';

const MSG_TIMEOUT = 3000

const ProfileView = () => {
  const [userInfoForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const [notificationPreferencesForm] = Form.useForm();

  const userProfile = useSelector(state => state.profile)
  const clientCredentials = useSelector(state => state.clientCredentials);
  const notificationPreferences = useSelector(state => state.notificationPreferences);
  const [_, forceUpdate] = useReducer(x => x + 1, 0);
  const { fetchingProfile,
    profileFetched,
    savingProfile,
    profileSaved,
    changingPassword,
    passwordSaved,
    error } = userProfile
  
  const {
    notificationPreferencesSaved,
    savingNotificationPreferences,
    disablePreferences
  } = notificationPreferences

  const programmaticAccessColumns = [{
    title: (<Typography.Text strong>API Key</Typography.Text>),
    dataIndex: 'key',
    render: value => <span className="fs-exclude">{value}</span>,
  }, {
    title: (<Typography.Text strong>Last Used</Typography.Text>),
    dataIndex: 'lastUsed',
    render: value => value && (<span>{ (new Date(value * 1000.0)).toLocaleString() }</span>),
  }, {
    title: '',
    key: 'action',
    width: 120,
    render: (value, record) => {
      const revokeOnClick = () => {
        revokeCredentials(record['key']);
      };
      const showConfirmDialog = () => {
        Modal.confirm({
          title: (<Typography.Text strong>Revoke API Key</Typography.Text>),
          content: (
            <span>Are you sure you want to revoke <Typography.Text strong>{record['key']}</Typography.Text>? This cannot be undone.</span>
          ),
          centered: true,
          onOk: revokeOnClick,
          okText: 'Revoke',
          okButtonProps: {
            style: {
              background: 'var(--red-6)'
            }
          },
          width: '45em',
          autoFocusButton: 'cancel',
          icon: <FontAwesomeIcon icon={faExclamationCircle} />
        });
      };

      return (
        <Button icon={<FontAwesomeIcon icon={faTrashAlt} />} onClick={showConfirmDialog}>Revoke</Button>
      )
    },
  }]

  let history = useHistory()

  const changesSavedMessage = 'Changes Saved!';
  const backendMsgResponse = (
    error && (
        (error.data.oktaError?.errorCauses || []).map(cause => cause?.errorSummary || '').join('. ') || 
        error.data.oktaError?.errorSummary || 
        error.data.oktaError || 
        error.data.apiError || 
        'An error occurred when trying to update your profile. Please try again later.'
    )
  ) || (
    clientCredentials.error && clientCredentials.error.data?.message
  );

  const templateData = notificationPreferences.templates.sort((a, b) => a.id > b.id).filter((template) => template.enabled && template.emailTarget == "ALL_USERS");
  const timezones = Intl.supportedValuesOf('timeZone');

  const [showSuccessMsg, setShowSuccessMsg] = useState(false)
  const [showPreferencesSuccessMsg, setShowPreferencesSuccessMsg] = useState(false)
  const [showErrorMsg, setShowErrorMsg] = useState(false)
  const [showPreferencesErrorMsg, setShowPreferencesErrorMsg] = useState(false)
  const [showErrorPswMsg, setShowErrorPswMsg] = useState(false)
  const [isUserInfoFormTouched, setIsUserInfoFormTouched] = useState(false)
  const [isNotificationPreferencesFormTouched, setIsNotificationPreferencesFormTouched] = useState(false)

  const [showClientCredentialCreatedMsg, setShowClientCredentialCreatedMsg] = useState(false)
  const onShowClientCredentialModalClosed = () => setShowClientCredentialCreatedMsg(false)
  
  const { saveProfile, fetchProfile, changePassword } = ProfileActions.useProfileActions()
  const { fetchCredentials, createCredentials, revokeCredentials } = ClientCredentialsActions.useCredentialsActions();
  const { fetchNotificationPreferences, saveNotificationPreferences, setDisablePreferences } = NotificationPreferencesActions.useNotificationPreferencesActions();

  useEffect(() => {
    document.title = "Profile"
    async function _fetchProfile() {
      await fetchProfile();
    }
    async function _fetchCredentials() {
      await fetchCredentials();
    }
    async function _fetchNotificationPreferences() {
      await fetchNotificationPreferences();
    }
    _fetchProfile();
    _fetchCredentials();
    _fetchNotificationPreferences();
  }, [fetchProfile, fetchCredentials, fetchNotificationPreferences]);

  useEffect(() => {
    if (clientCredentials.newCredential) {
      setShowClientCredentialCreatedMsg(true);
    }
  }, [clientCredentials.newCredential]);

  useEffect(() => {
    if (!fetchingProfile && !profileFetched) {
      history.push(`${ROUTES.USER_LOGIN}?qcRefer=${window.location.pathname}`)
    }
  }, [fetchingProfile, profileFetched]);

  useEffect(() => {
    if(profileSaved){
      setShowSuccessMsg(true)
      const timeoutId = setTimeout(() => {
        setShowSuccessMsg(false)
      }, MSG_TIMEOUT);

      return () => {
        clearTimeout(timeoutId)
      }
    }
  },[profileSaved])

  useEffect(() => {
    if(notificationPreferencesSaved){
      setShowPreferencesSuccessMsg(true)
      const timeoutId = setTimeout(() => {
        setShowPreferencesSuccessMsg(false)
      }, MSG_TIMEOUT);

      return () => {
        clearTimeout(timeoutId)
      }
    }
  },[notificationPreferencesSaved])

  useEffect(() => {
    if(passwordSaved){
      window.location.href = '/user/login?qcRefer=/user/account'
    }
  },[passwordSaved])

  useEffect(() => {
    if (error || clientCredentials.error) {
      setShowErrorMsg(true)
      setShowErrorPswMsg(true)
    }
  }, [error, clientCredentials.error])

  useEffect(() => {
    if (passwordSaved && error) {
      setShowErrorPswMsg(true)
    }
    if (passwordSaved && !error) {
      passwordForm.resetFields();
    }
  }, [passwordForm, passwordSaved]);

  const _goBack = () => {
    window.history.back();
  };

  const _saveProfile = (values) => {
    saveProfile(
      userInfoForm.getFieldsValue(null, meta => meta.touched),
      {
        email: userProfile.profile.email,
        firstName: values.firstName,
        lastName: values.lastName,
        timezone: values.timezone
      }
    )
    setIsUserInfoFormTouched(false)
  }

  const _changePassword = (formData) => {
    changePassword(formData.oldPassword, formData.newPassword);
  };

  const _processAndTrimTemplatePreferences = (data, endString) => {
    return data.filter((item) => item.endsWith(endString)).map((item) => +item.replace(endString, ''))
  }

  const _saveNotificationPreferences = (formData) => {
    const realTimeTemplates = _processAndTrimTemplatePreferences(formData.emailPreferences, '-realTime')
    const digestTemplates = _processAndTrimTemplatePreferences(formData.emailPreferences, '-dailyDigest')
    const digestExcludeTemplates = templateData.filter((t) => !digestTemplates.includes(t.id)).map((t) => t.id)

    saveNotificationPreferences({
      emailNotifications: formData.emailsEnabled,
      realTimeTemplates: realTimeTemplates,
      digestExcludeTemplates: digestExcludeTemplates
    });

    setIsNotificationPreferencesFormTouched(false)
  }

  const _getInitialCheckboxValues = () => {
    const realTimeTemplates = notificationPreferences?.preferences?.realTimeTemplates?.map((t) => t + '-realTime')
    const digestTemplates = templateData.filter((t) => !notificationPreferences?.preferences?.digestExcludeTemplates?.includes(t.id)).map((t) => t.id + '-dailyDigest')

    return realTimeTemplates === undefined ? [] : realTimeTemplates.concat(digestTemplates)
  }

  const _setDisablePreferences = (value) => {
    setDisablePreferences(!value)
  }

  return (
    fetchingProfile && !profileFetched ?
     <Stld.Content>
       <Spin
          size="large"
          data-cy="loadingSpinner"
        />
     </Stld.Content>
     :
      <ConfigProvider prefixCls="particle">
      <Stld.PageHeader onBack={window.history.length > 1 ? _goBack : undefined} title="User Profile" />
      <Stld.Content>
          {userProfile.idpProvided ? <Stld.Alert type="info" message="Your user is managed by an external identity provider" showIcon/> : null}
          <Stld.Card
            data-cy="userInfoCard"
            title="User Info" 
            style={{ minWidth: '780px' }}
            actions={[
              <>
                <Space>
                  <Button
                    type="primary"
                    data-cy="userInfoSaveButton"
                    disabled={fetchingProfile || savingProfile || !userInfoForm.isFieldsTouched()}
                    onClick={() => userInfoForm.submit()}
                    loading={fetchingProfile || savingProfile}
                  >
                    Save
                  </Button>
                </Space>
              </>
            ]}>
            <Form
              name="userInfo"
              labelAlign='left'
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              form={userInfoForm}
              onFinish={_saveProfile}
              onFinishFailed={({ errorFields }) => {
                userInfoForm.scrollToField(errorFields[0].name);
              }}
              onFieldsChange={
                setIsUserInfoFormTouched
              }
              onChange={forceUpdate}
              initialValues={{
                firstName: userProfile.profile.firstName,
                lastName: userProfile.profile.lastName,
                timezone: userProfile.profile.timezone
              }}
            >
              <Form.Item
                data-cy="firstNameControl"
                label='First Name'
                name="firstName"
                rules={[
                  {required: true, message: 'Please input your first name' },
                  {whitespace: true, message: 'First name cannot be a whitespace'}
                ]}
              >
                {userProfile.idpProvided ? <span>{userProfile.profile.firstName}</span> : <Input data-cy="firstNameInput" disabled={userProfile.idpProvided} />}
              </Form.Item>
              <Form.Item
                data-cy="lastNameControl"
                label='Last Name'
                name="lastName"
                rules={[
                  {required: true, message: 'Please input your last name' },
                  {whitespace: true, message: 'Last name cannot be a whitespace'}
                ]}
              >
                {userProfile.idpProvided ? <span>{userProfile.profile.lastName}</span> : <Input data-cy="lastNameInput" disabled={userProfile.idpProvided} />}
              </Form.Item>

              <Form.Item
                data-cy="emailAddressControl"
                label={'Email Address'}
              >
                {userProfile.profile.email}
              </Form.Item>
              <span style={{ fontSize: '16px', fontWeight: 600}}> Timezone </span>
              <Form.Item name="timezone" style={{marginBottom: '10px'}}>
                <Select
                showSearch placeholder="Select timezone" 
                style={{maxWidth: '40%', paddingTop: '10px'}}
                >
                  {timezones.map((timezone) => {
                    return <Select.Option value={timezone} key={timezone}> {timezone} </Select.Option>
                  })}
                </Select>
              </Form.Item>
              <span style={{ fontWeight: 100, fontSize: '12px'}}> Select your preferred timezone. Digest emails will arrive at 8am in your chosen timezone. </span>
            </Form>
          </Stld.Card>

          {showSuccessMsg && (
            <Stld.Alert message="Success Tips" description={changesSavedMessage} type="success" showIcon />
          )}
          { showErrorMsg && ((error && error.status === 400) || (clientCredentials.error && clientCredentials.error.status === 409)) && (
            <Stld.Alert message="Error" description={backendMsgResponse} type="error" showIcon />
          )}

          {!userProfile.idpProvided ? 
          <Stld.Card
            data-cy="passwordCard"
            title="Password"
            actions={[
              <Space>
                { showErrorPswMsg && error && error.status === 403 && (
                  <Stld.ChangesSavedLabel 
                    data-cy="backendMsgResponse"
                    error={true}
                  >
                    {backendMsgResponse}
                  </Stld.ChangesSavedLabel>
                )}
                <Button
                  type="primary"
                  data-cy="passwordSaveButton"
                  disabled={changingPassword || fetchingProfile || !passwordForm.isFieldsTouched()}
                  onClick={passwordForm.submit}
                  loading={fetchingProfile || changingPassword}
                >
                  Save
                </Button>
              </Space>
            ]}
          >
            <Form
              layout='horizontal'
              form={passwordForm}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              onFinish={_changePassword}
              onFinishFailed={({ errorFields }) => {
                passwordForm.scrollToField(errorFields[0].name);
              }}
              onChange={forceUpdate}
              initialValues={{
                oldPassword: '',
                newPassword: '',
                newPasswordRepeated: ''
              }}
            >
              <Form.Item
                data-cy="oldPassword"
                label='OLD PASSWORD'
                name="oldPassword"
                rules={[
                  {required: true, message: 'Please enter your old password' },
                ]}
              >
                <Input.Password data-cy="oldPasswordInput" />
              </Form.Item>
              <Form.Item
                data-cy="newPassword"
                label='NEW PASSWORD'
                name="newPassword"
                rules={[
                  {required: true},
                  PASSWORD_VALIDATOR(userProfile.profile.email),
                ]}
              >
                <PasswordInput username={userProfile.profile.email} data-cy="newPasswordInput" />
              </Form.Item>
              <Form.Item
                data-cy="confirmPassword"
                label='CONFIRM PASSWORD'
                name="newPasswordRepeated"
                rules={[
                  {required: true, message: 'Please confirm your password' },
                  (form) => ({
                    validator(_, value) {
                      if (!value || form.getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject();
                    },
                    message: 'The two passwords that you entered do not match'
                  }),
                ]}
              >
                <Input.Password data-cy="confirmPasswordInput" />
              </Form.Item>
            </Form>
          </Stld.Card> : null}
          <Stld.Card 
            title="Notification Preferences"
            actions={[
            <>
              <Space>
                {showPreferencesSuccessMsg && (
                  <Stld.ChangesSavedLabel 
                    data-cy="messageSavedChangePrefs"
                  >
                    <Stld.CheckMark data-cy="validatedPrefs">✓</Stld.CheckMark>
                    {changesSavedMessage}
                  </Stld.ChangesSavedLabel>
                )}
                { showPreferencesErrorMsg && (error && error.status === 400) && (
                  <Stld.ChangesSavedLabel 
                    data-cy="backendMsgResponse"
                    error={true}
                  >
                    {backendMsgResponse}
                  </Stld.ChangesSavedLabel>
                )}
                <Button
                  type="primary"
                  data-cy="notificationPreferencesSaveButton"
                  disabled={!isNotificationPreferencesFormTouched}
                  onClick={notificationPreferencesForm.submit}
                  loading={notificationPreferences.fetching || savingNotificationPreferences}
                >
                  Save
                </Button>
              </Space>
            </>
            ]}
          >
            <Form
            form={notificationPreferencesForm}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            onFinish={_saveNotificationPreferences}
            onFinishFailed={({ errorFields }) => {
              notificationPreferencesForm.scrollToField(errorFields[0].name);
            }}
            onFieldsChange={setIsNotificationPreferencesFormTouched}
            onChange={forceUpdate}
            initialValues={{
              emailsEnabled: notificationPreferences.preferences.emailNotifications,
              emailPreferences: _getInitialCheckboxValues()
            }}
            labelAlign='left'
            >
              <Form.Item label="Email Notifications" name="emailsEnabled" valuePropName='checked' style={{marginBottom: '0px', marginRight: '2.5%'}}>
                <Switch style={{float: 'right'}} defaultChecked onChange={_setDisablePreferences} size="large"/>
              </Form.Item>
              <span style={{fontSize: '12px', fontWeight: '100'}}>This controls only email notifications from the Quantcast Platform. Quantcast marketing and research emails are excluded.</span>
              <Form.Item label="" name="emailPreferences" style={{marginBottom: '0px', width: '135%'}}>
                <Checkbox.Group
                  disabled={disablePreferences} 
                  style={{width:'100%', marginTop: '10px'}}
                >
                  <Row style={{paddingBottom: '10px', fontWeight: '600'}}>
                    <Col span={16}>
                      <span> Email Preferences </span>
                    </Col>
                    <Col align='middle' span={4}>
                      <span> Real-time </span>
                    </Col>
                    <Col align='middle' span={4}>
                    <span> Digests </span>
                    </Col>
                  </Row>
                  {templateData.map((template) => {
                    return <Row label={template.id} key={template.id}>
                      <Col span={16}>
                          <Space direction='horizontal' size='small'>
                            <span>{template.name}</span>
                            <Tooltip title={template.description} placement="right">
                              <FontAwesomeIcon icon={faCircleInfo} />
                            </Tooltip>
                          </Space>
                      </Col>
                      <Col align='middle' span={4}>                 
                          <Checkbox value={template.id + "-realTime"} style={{ lineHeight: '32px' }}/>
                      </Col>
                      <Col align='middle' span={4}>
                          <Checkbox value={template.id + "-dailyDigest"} style={{ lineHeight: '32px' }}/>
                      </Col>
                    </Row>
                  })}
                </Checkbox.Group>
              </Form.Item>
              <span style={{ fontWeight: 100, fontSize: '12px'}}> Check 'Real-time' to receive immediate email notifications for an event and check 'Digests' if you would like to receive this notification in your daily digest email. </span>
            </Form>
          </Stld.Card>
        { clientCredentials.featureEnabled &&
        <>
          {clientCredentials?.credentialRevoked && <Stld.Alert message="API Key successfully revoked" type="success" showIcon closable={true} />}

          <Stld.Card 
            title="API Key"
            extra={
              <Button
                type="primary"
                loading={clientCredentials.fetchingProfile}
                disabled={clientCredentials.credentials.length >= Number(process.env.REACT_APP_MAX_CLIENT_CREDENTIALS)}
                onClick={() => createCredentials()}
              >
                Create API Key
              </Button>
            }
          >
            <Table
              columns={programmaticAccessColumns}
              dataSource={clientCredentials.credentials}
              pagination={false}
              loading={clientCredentials.fetchingProfile}
              />
          </Stld.Card>
        </>
        }
        <Stld.Collapsible
          style={{width: '780px', marginBottom: '30px'}}
          initiallyCollapsed={true}
          title="CCPA Requests"
        >
            In accordance with the California Consumer Privacy Act, California
            residents may request access to or deletion of their personal
            information. Please email{' '}
            <a href='mailto:privacy@quantcast.com'>privacy@quantcast.com</a> to
            request access to or deletion of your personal information associated
            with your user userProfile on{' '}
            <a href='https://www.quantcast.com'>quantcast.com</a>. Please be aware
            that requesting deletion may result in no longer being able to access
            this website.
        </Stld.Collapsible>
      </Stld.Content>

      <Modal 
        closable={false}
        cancelText={null}
        width={'auto'}
        cancelButtonProps={{style: {'display': 'none'}}}
        visible={showClientCredentialCreatedMsg} centered={true} onOk={onShowClientCredentialModalClosed} onCancel={onShowClientCredentialModalClosed}>
        <Result
          status="success"
          title="New credentials created!"
          subTitle={
            <div>
              <p>
                Store them safely before closing this window. If lost, you will need to create new credentials.
              </p>
              <div style={{'textAlign': 'left'}} className="fs-exclude">
                <table>
                  <tr>
                    <td style={{paddingRight: '30px'}}><b>Key:</b></td>
                    <td style={{whiteSpace: 'nowrap'}}>
                      {clientCredentials.newCredential?.key}
                    </td>
                    <td style={{paddingLeft: '30px'}}>
                      <Stld.CopyButton size="small" shape="circle" icon={<FontAwesomeIcon icon={faCopy} size="xs"/>} onClick={() => navigator.clipboard.writeText(clientCredentials.newCredential?.key)}/>
                    </td>
                  </tr>
                  <tr>
                    <td><b>Secret:</b></td>
                    <td style={{whiteSpace: 'nowrap'}}>
                      {clientCredentials.newCredential?.secret}
                    </td>
                    <td style={{paddingLeft: '30px'}}>
                      <Stld.CopyButton size="small" shape="circle" icon={<FontAwesomeIcon icon={faCopy} size="xs"/>} onClick={() => navigator.clipboard.writeText(clientCredentials.newCredential?.secret)}/>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          }
         />
      </Modal>
    </ConfigProvider>
  );
};

export default ProfileView;
