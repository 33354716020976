import React from 'react';
import { Button, Typography } from 'antd';
import { Card } from "../pages/ProductMatrix.styles";

import styled from 'styled-components';

export const CardTier = styled(Card)`
  width: 360px;
  margin: 8px;
  padding: 0;
  min-height: 433px;
  max-height: 568px;
  height: 60vh;
`;

export const H2 = styled.h2`
    margin: 0;
    font-weight: 600;
    font-size: 32px;
    color: var(--dark-blue)
`;

export const TierIcon = styled.span`
    font-size: 40px;
`;

export const TierDescription = styled.p`
    margin-top: 0px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e8e9ea;
    font-weight: 400;
    font-size: 14px;
    height: 82px;
    flex-shrink: 0;
`;

export const TierFeatures = styled.div`
    padding: 0;
    margin-bottom: 15px;
`

export const ListItem = styled.div`
    display: flex;
    justify-content: space-between;
`

export const ListIconGood = styled.div`
    font-size: 20px;
    width: 8%;
    color: var(--green-dark);
`

export const ListItemGoodText = styled.div`
    width: 92%;
`

export const ListIconBad = styled.div`
    width: 8%;
    color: #8C8C8C;
`

export const ListItemBadText = styled.div`
    width: 92%;
    color: #8C8C8C;
`

export const SignUpButton = styled(Button)`
  margin-top: auto;
  width: 100%;
`

const TierFeature = ({ type, keyVal, content }) => {
    // NOTE: bad feature not updated for rebrand
    return (
      <ListItem>
        {type === 'bad' ? (
          <ListIconBad key={`${keyVal}-icon`}>–</ListIconBad>
        ) : (
          <ListIconGood className="material-symbols-outlined" key={`${keyVal}-icon`}>
            check_circle
          </ListIconGood>
        )}
        {type === 'bad' ? (
          <ListItemBadText key={`${keyVal}-content`}>{content}</ListItemBadText>
        ) : (
          <ListItemGoodText key={`${keyVal}-content`}>{content}</ListItemGoodText>
        )}
      </ListItem>
    )
}

const ProductTierOption = ({
    disabled,
    icon,
    tierName,
    description,
    includedFeatures,
    missingFeatures,
    clickHandler,
    buttonText
}) => {
    return (
        <CardTier bodyStyle={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <TierIcon className='material-symbols-outlined'>{icon}</TierIcon>
            <H2>{tierName}</H2>
            <TierDescription>{description}</TierDescription>
            <TierFeatures>
                <Typography.Title level={5}>Features</Typography.Title>
                {includedFeatures.map((feature, index) => <TierFeature type='good' key={index} content={feature} />)}
                {missingFeatures.map((feature, index) => <TierFeature type='bad' key={index} content={feature} />)}
            </TierFeatures>
            <SignUpButton onClick={clickHandler} disabled={disabled}>
                {buttonText}
            </SignUpButton>
        </CardTier>
    )
}

export default ProductTierOption