import { ROUTES } from '../constants/routes';

const domainQuantcast = /^(http(s)?):\/\/(www.)?((test.|staging.|help.|help-test.)?quantcast.com)[\/a-zA-Z0-9@:%-._\+~#=]{0,256}]?/
const domainListApp = /^(http(s)?):\/\/(domain-list-ui-(test|staging|production)?.eks.qcinternal.io)[\/a-zA-Z0-9@:%-._\+~#=]{0,256}]?/
const testENV = /^http(s)?:\/\/localhost:(3000|3001)?[\/a-zA-Z0-9@:%-._\+~#=]{0,256}]?/

export const isValidUrl = (url) => domainQuantcast.test(url)
export const isValidInternalApp = (url) => domainListApp.test(url)
export const isValidTestEnv = (url) => testENV.test(url)

export function getValidRedirect(url) { 
    const origin = window.location.origin
    let urlWithOrigin
    
    if (!url) {
        return null
    }
    if (url.includes('/user/login') || url.includes('/signin/register')  ) {
        return null
    }
    if (url.match(/^\/(?!\/)[\/a-zA-Z0-9@:%-._\+~#=]{0,256}]?/)) { 
        return `${origin}${url}`
    } 

    urlWithOrigin = origin.match(domainQuantcast) && isValidTestEnv(url) || isValidUrl(url) || isValidInternalApp(url) ? url : `${origin}${ROUTES.HOME_PAGE}`
    urlWithOrigin = origin.match(domainListApp) && isValidTestEnv(url) || isValidUrl(url) || isValidInternalApp(url) ? url : `${origin}${ROUTES.HOME_PAGE}`
    urlWithOrigin = origin.match(testENV) && isValidTestEnv(url) || isValidUrl(url) || isValidInternalApp(url) ? url : `${origin}${ROUTES.HOME_PAGE}`
    

    return urlWithOrigin
}