import React from 'react';
import { Row, Col } from 'antd';
import styled from 'styled-components';

const AuthContainer = styled.div`
  ${({ type }) => type && `
    background: ${type === 'primary' ? 'var(--matter-bright-blue-light)' : 'var(--matter-gray-1)'};
  `}

  height: min-content;
  min-height: 100%;
  width: min-content;
  min-width: 100%;
  display: grid;
  align-items: center;
`

const QCPanelRow = styled(Row)`
  height: 100%;
  width: 100%;
`

const ContentCol = styled(Col)`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const ImageCol = styled(Col)`
  overflow: hidden;
`

const QCImage = styled.div`
  mask-position: right;
  mask-size: contain;
  mask-image: url("https://static.quantcast.com/images/global-illo-light.svg");
  mask-repeat: no-repeat;
  background-color: var(--matter-dark-blue);
  width: 47vw;
  height: 80%;
  margin-left: 7vw;
  margin-top: 10%;
`;

const QCPanelContainer = ({showImage, children, type }) => {
    return (
        <AuthContainer type={type} >
          <QCPanelRow justify="center">
            <ContentCol
              xs={24}
              md={24}
              lg={showImage ? 12 : 24}
              xl={showImage ? 12 : 24}
            >
              {children}
            </ContentCol>
            {showImage && (
              <ImageCol xs={0} md={0} lg={12} xl={12}>
                <QCImage />
              </ImageCol>
            )}
          </QCPanelRow>
        </AuthContainer>
    )
}

export default QCPanelContainer;