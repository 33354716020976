export const INITIAL_STATE = {
    fetching: true,
    fetched: false,
    error: null,
    preferences: {
        emailNotifications: true,
        realTimeTemplates: [],
        digestExcludeTemplates: []
    },
    templates: [],
    savingNotificationPreferences: false,
    notificationPreferencesSaved: false,
    disablePreferences: true,
}