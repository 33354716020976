import React from 'react';
import Alert from '@qc/particle/lib/components/alert'
import OktaSignIn from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/css/okta-sign-in.min.css';
import {oktaConfig} from '../config/okta';
import {setStateToken} from '../oktaConfigUtil';
import { ROUTES } from '../constants/routes';
import QCPanelContainer from '../components/QCPanelContainer';

import * as Stld from './Login.styles';
import { checkLoggedInAndRedirect } from '../utils/loggedInCheckRedirect';
import PinnedCard from '../components/PinnedCard';

class LoginView extends React.Component {
  constructor(props){
    super(props);
    const urlParams = new URLSearchParams(props.location.search);
    const loginHint = urlParams.get('login_hint');
    this.state = {
      isCaptchaActive: false,
      isSelfServeLogin: window.location.pathname === '/selfserve/login',
      source: urlParams.get('source'),
      msg: urlParams.get('msg'),
      captchaEndpoint: '',
      username: loginHint || undefined
    };
    checkLoggedInAndRedirect();
    this.oktaWidgetRef = React.createRef();
  }

  handleLoaded = () => {
    const { captchaEndpoint } = this.state
    
    // _DATADOG_SYNTHETICS_BROWSER is set by the bots on datadog.
    // When this happens we still hit the endpoint, for the IP to get checked.
    // The reason is that we have a problem on the recaptcha code not triggering
    // onload event on the tests.
    if (window._DATADOG_SYNTHETICS_BROWSER !== undefined) {
      fetch(captchaEndpoint, {
        method: 'POST',
        body: JSON.stringify({
          version:"v3",
          token: "invalid_token"
        })
      }).then((resp) => {
        if(resp.status !== 200) {
          this.setState({isCaptchaActive:true})
          this.widget.remove();
        } else {
          this.setState({isCaptchaActive:false})
          this.widget.renderEl({el: this.oktaWidgetRef.current});
        }
      });
      return;
    }

    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY_V3 , { action: "login" })
        .then(token => {
          fetch(captchaEndpoint, {
            method: 'POST',
            body: JSON.stringify({
              version:"v3",
              token: token
              })
          })
         .then((resp) => {
            //200 - API returns a successful result.
            //403 - API returns an unsuccessful result (recaptcha was solved but Google failed to validate the provided token)
            if(resp.status !== 200) {
              this.setState({isCaptchaActive:true})
              this.widget.remove();

              window.grecaptcha.render("reCaptchaV2", {
                'sitekey' : process.env.REACT_APP_CAPTCHA_SITE_KEY_V2,
                'expired-callback': this.recaptchaExpired,
                'callback': (token) => {
                  fetch(captchaEndpoint, {
                    method: 'POST',
                    body: JSON.stringify({
                      version:"v2",
                      token: token
                    })
                  })
                  .then((resp) => {
                    if(resp.status === 200) {
                      this.setState({isCaptchaActive:false})
                      this.widget.renderEl({el: this.oktaWidgetRef.current});
                    }
                  })
                }
              });
            } else {
              this.widget.renderEl({el: this.oktaWidgetRef.current});
            }
           })
          .catch((err) => {
             console.log('error', err)
          })
         })
     })
  }

  recaptchaExpired = () => {
    this.widget.remove();
  }

  async componentDidMount() {
    const testEnv = 'https://test.quantcast.com'

    if(window.location.origin.startsWith('http://localhost') && process.env.REACT_APP_BASE_URL === testEnv) {
      this.setState({captchaEndpoint: `${window.location.origin}/users/api/verify/recaptcha`})
    } else {
      this.setState({captchaEndpoint: `${process.env.REACT_APP_BASE_URL}/users/api/verify/recaptcha`})
    }

    document.title = "Login"
    const script = document.createElement("script")
    script.async= true;
    script.src = `https://google.com/recaptcha/api.js?render=${process.env.REACT_APP_CAPTCHA_SITE_KEY_V3}`
    script.onload = () => {
      this.handleLoaded();
    }
    script.onerror  = () => {
      console.error("Couldn't load recaptcha script!")
    }
    document.body.appendChild(script)
    
    const modifiedOktaConfig = {
      ...oktaConfig,
      username: this.state.username,
      registration: {
        click: () => {
          if (this.state.isSelfServeLogin) {
            this.props.history.push({
              pathname: ROUTES.PRO_SIGNUP,
              search: this.props.location.search
            });
          } else {
            this.props.history.push({
              pathname: ROUTES.USER_SIGNUP,
              search: this.props.location.search
            });
          }
        }
      }
    }
    setStateToken(modifiedOktaConfig);
    this.widget = new OktaSignIn(modifiedOktaConfig);
    this.widget.on('afterRender', (context) => {
      // upon idp resolution okta widget changes the url and looses the qcRefer state. The below change restores that.
      if (context.controller === 'primary-auth') {
        setTimeout(() => {
          window.history.pushState({}, null, '/user/login' + this.props.location.search ? this.props.location.search : '');
        }, 100);  
      }

      (document.getElementById('help-links-container') || {style: {}}).style.display = '';
      document.querySelectorAll('[data-se="needhelp"]').forEach(a => a.style.display = 'none');

      const usernameInput = document.getElementById('idp-discovery-username') || document.getElementById('account-recovery-username')
      if (usernameInput) {
        usernameInput.placeholder = 'Email Address'
      }
      const passwordInput = document.getElementById('okta-signin-password')
      if (passwordInput) {
        passwordInput.placeholder = 'Password'
      }

    });
    await this.refreshAccessToken();
  }

  async refreshAccessToken() {
    return fetch('/token/refreshAccessToken', {method: "POST"});
  }

  componentWillUnmount() {
    this.widget.remove();
  }

  render() {
    const { isCaptchaActive, isSelfServeLogin, source, msg } = this.state;
    
    return (
      <>
        {
          isSelfServeLogin && source === 'activation' &&
          <Alert message="Activation successful!" description="Your quantcast.com account has been activated! Please use your password to login!" type="success" showIcon />
        }

        {
          isSelfServeLogin && source === 'activation_error' &&
          <Alert message="Activation Failed" description="We are not able to verify your quantcast account." type="error" showIcon />
        }

        {
          source === 'pub_onboarding_error' &&
          <Alert message="Activation Failed" description={msg || "We are not currently able to activate your quantcast account."} type="error" showIcon />
        }

        <QCPanelContainer showImage type="primary">
          <div style={{ padding: '0 50px', minHeight: '700px' }}>
            <PinnedCard theme="dark">
              <Stld.QuantcastLogoContainer>
                <Stld.QuantcastLogo onClick={() => {window.location.href = ROUTES.HOME_PAGE}} />
              </Stld.QuantcastLogoContainer>
              {isCaptchaActive ? (
                <Stld.BoxCaptcha>
                  <div 
                    id="reCaptchaV2"
                    className="g-recaptcha"
                    data-expired-callback="recaptchaExpired"
                    data-sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V2}
                  /> 
                </Stld.BoxCaptcha> 
              ): (
                  <Stld.OktaAuthContainer id="okta-auth-container" ref={this.oktaWidgetRef}/>
                )
              }
              <div className="g-recaptcha" 
                  data-sitekey={process.env.REACT_APP_CAPTCHA_SITE_KEY_V3}
                  data-size="invisible"
              />
            </PinnedCard>
          </div>
        </QCPanelContainer>
      </>
    )
  }
}

export default LoginView;
