import {OktaAuth} from '@okta/okta-auth-js';
import React, {useMemo} from 'react';
import {oktaConfig} from '../config/okta';
import { getStateToken } from "../oktaConfigUtil";

export const LoginFromSessionView = (props) => {
    useMemo(() => {
        const oktaAuth = new OktaAuth({
            issuer: oktaConfig.authParams.issuer,
            
            clientId: oktaConfig.clientId,
            redirectUri: oktaConfig.redirectUri,
            scopes: oktaConfig.authParams.scopes,
            
            responseType: oktaConfig.authParams.responseType,
            pkce: oktaConfig.authParams.pkce,
            
            cookies: oktaConfig.authParams.cookies,
            tokenManager: oktaConfig.authParams.tokenManager,
            transactionManager: oktaConfig.authParams.transactionManager,
            storageManager: oktaConfig.authParams.storageManager,
        });
        oktaAuth.signInWithRedirect({
            state: getStateToken()
        });
    }, []);
    

    return <></>;
}

export default LoginFromSessionView;