import md5 from 'md5';
import {
  USER_REGISTRATION_STARTED,
  USER_REGISTRATION_FAILURE,
  USER_REGISTRATION_SUCCESS,
  USER_REGISTRATION_IDP_PROVIDED,
} from "./reducer";

export const CREATE_USER_ENDPOINT = '/users/api/signup';

export const registerUser = (
  registrationType, email, firstName, lastName, redirectUrl,
  recaptchaVersion, recaptchaToken,
  password, signupAttributes
) => {
    return dispatch => {
      dispatch({
        type: USER_REGISTRATION_STARTED
      })
      return fetch(CREATE_USER_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          email, firstName, lastName,
          qcRefer: redirectUrl, recaptchaVersion, recaptchaToken,
          password, signupAttributes
        })
      }).then(response => {
        if (response.status === 403 || response.status === 409 || response.status === 417) {
          dispatch({ type: USER_REGISTRATION_FAILURE, payload: `${response.status}` });
          return;
        }
        if (response.status > 299) {
          dispatch({ type: USER_REGISTRATION_FAILURE, payload: '500' });
          return;
        }
        console.log("Success")
        return response.json().then((data) => {
          if (data.status === "IDP_PROVIDED" ) {
            dispatch({ type: USER_REGISTRATION_IDP_PROVIDED });
          } else {
            dispatch({ type: USER_REGISTRATION_SUCCESS });
          }
          try {
            window.dataLayer.push({
              'event': 'Sign Up Complete',
              'userID': md5(email),
              'type': registrationType,
              'isQCEmployee': email.endsWith('@quantcast.com'),
            });
          } catch(err) { }
        });
      })
      .catch(error => {
        console.log(error)
        dispatch({
          type: USER_REGISTRATION_FAILURE,
          payload: '500'
        });
      });
    }
  }