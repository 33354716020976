import React from 'react';
import styled from 'styled-components';
import Input from 'antd/es/input';

import { executeRules, REQUIREMENTS } from '../constants/password';

export const Ul = styled.ul`
  list-style-type: none;
  padding-bottom: 0;
  margin-bottom: 0;

  li {
    font-size: 12px;
  }
`;

export const LiContainer = styled.span`
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const Check = styled.span`
  color: var(--matter-gray-4);
  font-size: 16px;
  margin-right: 8px;
`;


export const CheckPassed = styled(Check)`
  color: var(--matter-green-dark);
`;

export const CheckFailed = styled(Check)`
  color: var(--matter-red-dark);
`;

export const LiContent = styled.span`
  font-weight: ${({ bold }) => (bold ? 'bold' : 'unset')};
  color: ${({ bold }) => (bold ? 'var(--matter-red-dark)' : 'var(--matter-gray-4)')};
`;

export const PASSWORD_VALIDATOR = (username) => {
  return () => ({
    validator(_, value) {
      if (executeRules(username, value).every(t => t === true)) {
        return Promise.resolve();
      }
      return Promise.reject();
    }
  });
}

const PasswordRuleItem = ({rules, idx, requirement}) => {
  return (
    <li key={`requirement ${idx}`}>
      <LiContainer
        data-cy={`requirement ${idx}`}
      >
        {rules[idx] === null && <Check className="material-symbols-outlined">check_circle</Check>}
        {rules[idx] === true && <CheckPassed className="material-symbols-outlined">check_circle</CheckPassed>}
        {rules[idx] === false && <CheckFailed className="material-symbols-outlined">cancel</CheckFailed>}
        <LiContent 
          bold={rules[idx] === false}
          data-cy={requirement}
        >
          {requirement}
        </LiContent>
      </LiContainer>
    </li>
  )
};

const PasswordRules = ({hideRules, rules}) => {
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <Ul>
        {!hideRules && REQUIREMENTS.map((requirement, idx) => {
          if (idx % 2 === 0) return;
          return (
            <PasswordRuleItem key={`r_${idx}`} rules={rules} idx={idx} requirement={requirement} />
          )
        })}
      </Ul>
      <Ul>
        {!hideRules && REQUIREMENTS.map((requirement, idx) => {
          if (idx % 2 !== 0) return;
          return (
            <PasswordRuleItem key={`r_${idx}`} rules={rules} idx={idx} requirement={requirement} />
          )
        })}
      </Ul>
    </div>
  )
}

const PasswordInput = (props) => {
    const { username, hideRules, ...remainingProps } = props;
    const rules = executeRules(username, props.value);

    return <>
        <Input.Password
            {...remainingProps}
            type='password'
            autoComplete='new-password'
            style={{ marginBottom: '10px' }}
            iconRender={(visible) =>
              visible ? (
                <span>
                  <span className="material-symbols-outlined">visibility</span>
                </span>
              ) : (
                <span>
                  <span className="material-symbols-outlined">visibility_off</span>
                </span>
              )
            }
        />
        <PasswordRules rules={rules} hideRules={hideRules} />
    </>
}

export default PasswordInput;