import { Button, Card as ParticleCard } from 'antd'
import { Collapsible as ParticleCollapsible } from '@qc/matter/lib/components/card'
import ParticleAlert from '@qc/particle/lib/components/alert'
import ParticlePageHeader from '@qc/particle/lib/components/page-header'

import styled from 'styled-components';

export const PageHeader = styled(ParticlePageHeader)`
  background-color: var(--gray-1);
  margin-bottom: 10px;
`

export const Container = styled.div`
  min-width: 280;
`;
export const Title = styled.span`
  font-size: 22;
`;

export const SubTitle = styled.span`
  font-size: 14;
`;

export const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

export const BackButton = styled.div`
  margin: 20px 0 -10px;
  min-width: 780px;
`;

export const CopyButton = styled(Button)`
  outline-style: none !important;
  border: none !important;
`

export const Card = styled(ParticleCard)`
  width: 780px;
  margin-bottom: 10px;

  .particle-card-actions > li {
    text-align: right;
    padding-right: 10px;
  }
`;

export const Alert = styled(ParticleAlert)`
  width: 780px;
  margin-bottom: 10px;
`

export const Collapsible = styled(ParticleCollapsible)`
  width: 780px;
  margin-bottom: 20px;
`

export const FooterCard = styled(ParticleCard)`
  align-items: flex-end;
  min-width: 780px;
  .simple-card__content {
    display: flex;
  }
`;

export const LabelForm = styled.span`
  color: var(--gray-8);
  font-size: 14px;
  padding-top: 4px;
`;

export const ErrorLabelForm = styled.span`
  color: var(--red-6);
  position: absolute;
`;

export const CheckMark = styled.span`
  color: var(--green-6);
  height: 8p;
  margin-right: 8px;
  width: 8px;
`;

export const ChangesSavedLabel = styled.div`
  align-items: center;
  color:${({error}) => (error ? 'var(--red-6)' : 'var(--green-6)')};
  display: flex;
  margin-right: 10px;
  font-size: 14px;
`;

export const CCPAlert = styled.div`
  background-color: white;
  margin: 30px 0;
  border: 1px solid #e8e9ea;
  border-radius: 5px;
  width: 780px;
  transition: opacity 500ms ease-in;
  position: relative;
`;

export const InformationText = styled.div`
  width: 740px;
  font-size: 12px;
  padding: 0 0 10px 30px;
  display: none;
  transition: display 500ms linear;

  &.show--card {
    display: block;
    font-size: 14px;
    line-height: 21px;
    transition: display 500ms linear;
  }
`;

export const InfoTitle = styled.h4`
  font-size: 14px;
  font-weight: 600;
  color: var(--gray-9);
  width: 780px;
  margin: 10px 0;
  cursor: pointer;

  &::before, &::after {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: FontAwesome;
    font-weight: 900;
    padding: 0 10px;
  }

  &::before {    
    content: "\f06a";
  }

  &::after {
    content: "\f105";
    position: absolute;
    right: 10px;
    font-size: 17px;
    transition: transform 200ms ease-in;

  }

  &:hover&:after {
    color: rgb(53, 170, 216);
  }

  &.show--card {
    &::after {
      content: "\f105";
      position: absolute;
      right: 10px;
      font-size: 17px;
      transform: rotate(90deg);
      transition: transform 200ms ease-in;
    }
  }
  
`;
