import { ROUTES } from "../../../constants/routes"
const ACTIVATE_USER_ENDPOINT = '/users/api/users/token/';
import md5 from 'md5';

let redirectUrl = "";

export const activateUser = data => {
  const urlProps = []
  if (data.loginHint) {
    urlProps.push("login_hint=" + encodeURIComponent(data.loginHint))
  }
  if (data.redirectUrl != null) {
    urlProps.push("qcRefer=" + encodeURIComponent(data.redirectUrl))
  }

  redirectUrl = "?" + urlProps.join("&")
  
  return dispatch => {
    dispatch({
      type: 'USER_ACTIVATION_TOKEN_STARTED'
    })
    fetch(ACTIVATE_USER_ENDPOINT + data.activationToken, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data),
    })
      .then(response => {
        if (response.status === 401) {
          dispatch({
            type: 'USER_ACTIVATION_TOKEN_FAILURE',
            error: '401',
            errorSummary: `Sorry, this invite has expired.`
          });
          return;
        }
        if (response.status == 400) {
          return response.json().then(body => {
            return (body?.oktaError?.errorCauses || []).map(cause => cause?.errorSummary || '').join('. ') ||
              body?.oktaError?.errorSummary || 
              body?.apiError || 
              'An error occurred when trying to activate your account. Please try again later.';
          }).then(errorSummary => {
            dispatch({
              type: 'USER_ACTIVATION_VALIDATION_FAILURE',
              error: '400',
              errorSummary
            });
          });
        }
        if (response.status > 299) {
          dispatch({
            type: 'USER_ACTIVATION_OTHER_FAILURE',
            error: '500',
            errorSummary: 'An error occurred when trying to activate your account. It is possible your activation link has expired, please sign up again to get one resent.'
          });
          return;
        }
        try {
          window.dataLayer.push({
            'event': 'Password Created',
            'userID': !!data.loginHint ? md5(data.loginHint) : '',
            'isQCEmployee': !!data.loginHint && data.loginHint.endsWith('@quantcast.com'),
            'eventCallback' : function() {
              window.location.href = `/user/login${redirectUrl}`;
            },
            'eventTimeout' : 2000,
          });
        } catch(err) {
          return window.location.href = `/user/login${redirectUrl}`;
        }
      })
      .catch(error => {
        dispatch({
          type: 'USER_ACTIVATION_OTHER_FAILURE',
          error,
          errorSummary: `An error occurred when trying to activate your account. It is possible your activation link has expired, please sign up again to get one resent.`
        });
      });
  };
};
