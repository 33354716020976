import React, { useEffect, useState } from "react";
import Alert from '@qc/matter/lib/components/alert'
import { Button, ConfigProvider, Form, Input } from 'antd'
import Space from '@qc/matter/lib/components/space'
import Tooltip from '@qc/matter/lib/components/tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faQuestionCircle } from '@fortawesome/pro-light-svg-icons/faQuestionCircle'
import md5 from 'md5';

import { connect } from "react-redux"
import { getForwardUrl } from "../oktaConfigUtil"
import { getValidRedirect } from '../utils/whiteListRedirect'

import * as Stld from "./ActivateView.styles"
import * as UserActivationActions from '../store/reducers/user_activation/actions'
import PasswordInput, { PASSWORD_VALIDATOR } from "../components/PasswordInput"
import { PSClickWrap } from '@pactsafe/pactsafe-react-sdk'
import QCPanelContainer from "../components/QCPanelContainer";
import { ROUTES } from "../constants/routes";
import { ORG_TYPES } from "../constants/signup";
import PinnedCard from "../components/PinnedCard";
import COUNTRIES from '../constants/countries.json';

const ActivateView = ({
  activation: {
    error,
    errorSummary,
    loading
  },
  activateUser,
  location
}) => {
  const urlParams = new URLSearchParams(location.search);
  const loginHint = urlParams.get('login_hint');
  const firstNameHint = urlParams.get('first_name');
  const lastNameHint = urlParams.get('last_name');
  const orgNameHint = urlParams.get('org_name');
  const countryCode = urlParams.get('country');
  const countryHint = COUNTRIES.find(c => c.code === countryCode)?.text || "";
  const orgType = (urlParams.get('orgtype') || '').toLowerCase();
  const [form] = Form.useForm();
  const activationToken = window.location.pathname.split("/")[2];
  const forwardUrl = getForwardUrl();
  const redirectUrl = getValidRedirect(forwardUrl);
  const [signed, setSigned] = useState((firstNameHint && lastNameHint) ? true : false)

  const isSelfServeAdvertise = [
    ORG_TYPES.SALES_PROSPECTING,
    ORG_TYPES.TIERED_SELF_SERVE
  ].indexOf(orgType) >= 0 || orgNameHint !== null;
  
  useEffect(() => {
    try {
      window.dataLayer.push({
        'event': 'Email Confirmed',
        'userID': md5(loginHint),
        'isQCEmployee': loginHint.endsWith('@quantcast.com'),
      });
    } catch(err) { }
  }, [loginHint])

  useEffect(() => {
    if (firstNameHint) {
      form.setFieldsValue({ firstName: firstNameHint });
    }
    if (lastNameHint) {
      form.setFieldsValue({ lastName: lastNameHint });
    }
  }, [firstNameHint, lastNameHint])

  const _activateUser = (formData) => {
    activateUser(
      loginHint,
      firstNameHint || formData.firstName,
      lastNameHint || formData.lastName,
      formData.password,
      activationToken,
      redirectUrl,
      isSelfServeAdvertise,
      orgType
    );
  };

  const labelWithTooltip = (
    <Space>
      Email Address
      <Tooltip
        id="tooltip"
        title="Your email address is set in Quantcast. Please contact your IT administrator to change it."
        placement="top"
      >
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Tooltip>
    </Space>
  );

  return (
    <ConfigProvider prefixCls="matter">
      <QCPanelContainer type="secondary" showImage>
        <Stld.Content>
          <PinnedCard theme="light">
            <Stld.QuantcastLogoContainer>
              <Stld.QuantcastLogo onClick={() => {window.location.href = ROUTES.HOME_PAGE}} />
            </Stld.QuantcastLogoContainer>
            <div>
              <div data-cy="header">
                <Stld.H3 data-cy="qText">Activate your Account</Stld.H3>
              </div>
              {error === "403" ? <Alert message={errorSummary} type="error" showIcon data-cy="errorMessageTokenExpired" /> :
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={(formData) => _activateUser(formData)}
                  requiredMark={false}
                  style={{ width: '450px' }}
                >
                  {!!loginHint ? 
                    <Stld.FormControl
                      data-cy="emailAddressControl"
                      label={labelWithTooltip}
                      value={loginHint}
                    >
                      <Input disabled={true} placeholder={loginHint} type="text" size="large"  />
                    </Stld.FormControl> : null }

                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', gap: '20px' }}>
                    <Stld.FormControl
                      data-cy="firstNameControl"
                      label="First Name"
                      style={{ width: '50%' }}
                      name="firstName"
                    >
                      <Input data-cy="firstNameInput" disabled={!!firstNameHint} placeholder={firstNameHint || "Type in your name"} type="text" size="large"  />
                    </Stld.FormControl>
                    
                    <Stld.FormControl
                      data-cy="lastNameControl"
                      label="Last Name"
                      style={{ width: '50%' }}
                      name="lastName"
                    >
                      <Input data-cy="lastNameInput" disabled={!!lastNameHint} placeholder={lastNameHint || "Type in your name"} type="text" size="large"  />
                    </Stld.FormControl>
                  </div>
              
                  {orgNameHint && 
                    <Stld.FormControl
                      data-cy="orgNameControl"
                      label="Organization Name"
                    >
                      <Input data-cy="orgNameInput" disabled={!!orgNameHint} placeholder={orgNameHint || "Type in your organization name"} type="text" size="large"  />
                    </Stld.FormControl>
                  }

                  {countryHint && 
                    <Stld.FormControl
                      data-cy="countryControl"
                      label="Country"
                    >
                      <Input data-cy="countryInput" disabled={!!countryHint} placeholder={countryHint} type="text" size="large"  />
                    </Stld.FormControl>
                  }

                  <Stld.FormControl
                    data-cy="passwordControl"
                    label="Password"
                    name="password"
                    rules={[
                      PASSWORD_VALIDATOR(loginHint),
                    ]}
                    style={{height: "175px", marginBottom: "-15px"}} // Removes a weird 'bounce' effect
                  >
                    <PasswordInput
                      username={loginHint}
                      data-cy="passwordInput"
                      placeholder="Type your new password here"
                      id="newPassword"
                      size="large"
                      onChange={() => {form.validateFields(['confirmPassword'])}}
                    />
                  </Stld.FormControl>

                  <Stld.FormControl
                    data-cy="confirmPasswordControl"
                    label="Confirm Password"
                    name="confirmPassword"
                    rules={[
                      {required: true, message: 'Please confirm your password' },
                      (form) => ({
                        validator(_, value) {
                          if (!value || form.getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject();
                        },
                        message: 'The two passwords that you entered do not match'
                      }),
                    ]}
                    style={{height: "100px", marginBottom: "0px"}} // Removes a weird 'bounce' effect
                  >
                    <Input.Password
                      data-cy="confirmPasswordInput"
                      placeholder="Retype your new password here"
                      id="confirmPassword"
                      size="large"
                      iconRender={(visible) =>
                        visible ? (
                          <span>
                            <span className="material-symbols-outlined">visibility</span>
                          </span>
                        ) : (
                          <span>
                            <span className="material-symbols-outlined">visibility_off</span>
                          </span>
                        )
                      }
                    />
                  </Stld.FormControl>
                  {!lastNameHint || !firstNameHint ? 
                    <PSClickWrap
                      allowDisagreed
                      clickWrapStyle="combined"
                      accessId={process.env.REACT_APP_PACT_SAFE_ACCESS_ID || ''}
                      groupKey={ (isSelfServeAdvertise ? process.env.REACT_APP_PACT_SAFE_ADVERTISE_GROUP_KEY : process.env.REACT_APP_PACT_SAFE_GROUP_KEY) || ''}
                      signerId={loginHint}
                      onValid={() => setSigned(true)}
                      onInvalid={() => setSigned(false)}
                    /> : null
                  }
                  <Stld.FormControl labelCol={{ span: 12 }} rowCol={{ span: 12 }}>
                      <Button type="primary" htmlType="submit" loading={loading} disabled={loading || !signed}>
                          Save
                      </Button>
                  </Stld.FormControl>
                  {(error === "500" || error === "400") ? <Stld.ErrorMessageApi data-cy="errorMessageApi"><p>{errorSummary}</p></Stld.ErrorMessageApi> : null}
                  {error === "401" ? (
                    <Stld.ErrorMessageApi>
                      <p>{errorSummary} Please <a href={isSelfServeAdvertise? '/selfserve/register?orgtype=tiered_self_serve&product_tier=pro' : ROUTES.USER_SIGNUP}>sign up</a> again to receive a new one.</p>
                      </Stld.ErrorMessageApi>
                  ) : null}
                </Form>
              }
            </div>
          </PinnedCard>
        </Stld.Content>
      </QCPanelContainer>
    </ConfigProvider>
  );
};

const mapStateToProps = state => ({
  profile: state.profile,
  activation: state.activation,
  loading: state.loading
});

const mapDispatchToProps = dispatch => ({
  activateUser: (loginHint, firstName, lastName, password, activationToken, redirectUrl, isSelfServeAdvertise, orgType) =>
    dispatch(UserActivationActions.activateUser({ loginHint, firstName, lastName, password, activationToken, redirectUrl, isSelfServeAdvertise, orgType }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivateView);
