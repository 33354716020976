import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch } from 'react-router-dom'
import { GTM } from './gtm';
import App from './App';
import { Provider } from 'react-redux';
import configureStore from './store';

import './styles/';

GTM(window, document, 'script', 'dataLayer', 'GTM-5JPC9W');

const store = configureStore({});

if(window.location.pathname=="/signin") {
  // Okta replaces the url with /signin. This is ok and doesn't impact the user experience, but if they refresh we want them on the correct page.
  window.location.href = `/user/login${window.location.search ? window.location.search : ''}`;
} else {
  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
            <App />
        </Switch>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root')
  );
}

