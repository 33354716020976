export const ROUTES = {
  HOME_PAGE: '/',
  USER_LOGIN: '(/user/login|/signin|/selfserve/login)',
  RECOVER_FROM_SESSION: '/user/login/callback',
  USER_ACCOUNT: '/user/account',
  WELCOME_TOKEN: '/welcome/:token',
  USER_SIGNUP: '/signin/register',
  SELFSERVE_SIGNUP: '/selfserve/register',
  PRODUCT_MATRIX: '/selfserve/tiers',
  MARKETER_SIGNUP: '/user/tieredSignUp',
  USER_PASSWORD_RESET: '/signin/reset-password',
  USER_FORGOT_PASSWORD: '/signin/forgot-password',
  PRO_SIGNUP: '/selfserve/register?orgtype=tiered_self_serve&product_tier=pro'
};
