import raw from 'raw.macro';
import { handleResponse } from '../../helpers/handleResponse'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useMemo } from 'react';

export const fetchingNotificationPreferences = () => ({
    type: 'FETCHING_NOTIFICATION_PREFERENCES'
});

export const savingNotificationPreferences = () => ({
    type: 'SAVING_NOTIFICATION_PREFERENCES'
});

export const useNotificationPreferencesActions = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    return useMemo(() => ({
        fetchNotificationPreferences: () => {
            dispatch(fetchingNotificationPreferences())
            return Promise.all([fetch('/ads/apiv2/manager/graphql', {
                method: 'POST',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    operationName: 'ListNotificationTemplates',
                    query: raw('./../../gql/listNotificationTemplates.gql'),
                    variables: {}
                })
            }).then(handleResponse(history)),
            fetch('/ads/apiv2/manager/graphql', {
                method: 'POST',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    operationName: 'FetchNotificationPreferences',
                    query: raw('./../../gql/fetchNotificationPreferences.gql'),
                    variables: {}
                })
            }).then(handleResponse(history))])
            .then((resp) => {
                dispatch({
                    type: 'FETCH_NOTIFICATION_PREFERENCES_SUCCESS',
                    disablePreferences: !resp[1].data?.getNotificationPreferences?.preferences?.emailNotifications,
                    preferences: {
                        emailNotifications: resp[1].data?.getNotificationPreferences?.preferences?.emailNotifications,
                        realTimeTemplates: resp[1].data?.getNotificationPreferences?.preferences?.realTimeTemplates,
                        digestExcludeTemplates: resp[1].data?.getNotificationPreferences?.preferences?.digestExcludeTemplates
                    },
                    templates: resp[0].data?.listNotificationTemplates?.templates
                })
            })
            .catch(error => {
                dispatch({
                    type: 'FETCH_NOTIFICATION_PREFERENCES_FAILURE',
                    error
                })
            });
        },

        saveNotificationPreferences: (preferences) => {
            dispatch(savingNotificationPreferences())
            return fetch('/ads/apiv2/manager/graphql', {
                method: 'POST',
                mode: 'same-origin',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    operationName: 'SaveNotificationPreferences',
                    query: raw('./../../gql/saveNotificationPreferences.gql'),
                    variables: { preferences }
                })
            })
            .then(handleResponse(history))
            .then((resp) => {
                dispatch({
                    type: 'SAVE_NOTIFICATION_PREFERENCES_SUCCESS',
                    preferences: {
                        emailNotifications: resp.data?.saveNotificationPreferences?.preferences?.emailNotifications,
                        realTimeTemplates: resp.data?.getNotificationPreferences?.preferences?.realTimeTemplates,
                        digestExcludeTemplates: resp.data?.getNotificationPreferences?.preferences?.digestExcludeTemplates
                    }
                })
            })
            .catch(error => {
                dispatch({
                    type: 'SAVE_NOTIFICATION_PREFERENCES_FAILURE',
                    error
                })
            });
        },

        setDisablePreferences: (disablePreferences) => {
            dispatch({
                type: 'SET_DISABLE_PREFERENCES',
                disablePreferences: disablePreferences
            })
        }
    }), [dispatch, history]);
}