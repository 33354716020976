import { INITIAL_STATE } from "./initialState";

const notificationPreferencesReducer = (
    state = INITIAL_STATE,
    action
    ) => {
        switch (action.type) {
            case 'FETCHING_NOTIFICATION_PREFERENCES':
                return { ...state, fetching: true, error: null };
            case 'SAVING_NOTIFICATION_PREFERENCES':
                return { ...state, notificationPreferencesSaved: false, savingNotificationPreferences: true, error: null };
            case 'FETCH_NOTIFICATION_PREFERENCES_SUCCESS': {
                const { disablePreferences, preferences, templates } = action
                return {
                    ...state,
                    fetching: false,
                    fetched: true,
                    disablePreferences,
                    preferences,
                    templates
                };
            }
            case 'SAVE_NOTIFICATION_PREFERENCES_SUCCESS': {
                const { preferences } = action
                return {
                    ...state,
                    savingNotificationPreferences: false,
                    notificationPreferencesSaved: true,
                    preferences
                };
            }
            case 'FETCH_NOTIFICATION_PREFERENCES_FAILURE': {
                const { error } = action
                return {
                    ...state,
                    fetching: false,
                    fetched: false,
                    error: error
                };
            }
            case 'SAVE_NOTIFICATION_PREFERENCES_FAILURE': {
                const { error } = action
                return {
                    ...state,
                    savingNotificationPreferences: false,
                    notificationPreferencesSaved: false,
                    error: error
                };
            }
            case 'SET_DISABLE_PREFERENCES': {
                const { disablePreferences } = action
                return {
                    ...state,
                    disablePreferences
                }
            }
        default:
            return state;
    }
};

export default notificationPreferencesReducer;
