import { ConfigProvider } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import LoginView from './pages/LoginView';
import ProfileView from './pages/ProfileView';
import ActivateView from './pages/ActivateView'
import SignUpView from './pages/SignUpView';
import LoginFromSessionView from './pages/LoginFromSessionView';
import { ROUTES } from './constants/routes';

import * as Stld from './App.styles'
import MarketerSignUpView from './pages/MarketerSignUpView';
import { ProductMatrix } from './pages/ProductMatrixView';

export const App = () => {
  return (
    <ConfigProvider prefixCls="particle">
      <Stld.ContentRoot>
        <Switch>
          <Route exact path={ROUTES.RECOVER_FROM_SESSION} component={LoginFromSessionView} />
          <Route exact path={ROUTES.USER_LOGIN} component={LoginView} />
          <Route path={ROUTES.USER_SIGNUP} component={SignUpView} />
          <Route path={ROUTES.USER_PASSWORD_RESET} component={LoginView} />
          <Route path={ROUTES.USER_FORGOT_PASSWORD} component={LoginView} />
          <Route path={ROUTES.USER_ACCOUNT} component={ProfileView} />
          <Route path={ROUTES.WELCOME_TOKEN} component={ActivateView} />
          <Route path={ROUTES.MARKETER_SIGNUP} component={MarketerSignUpView} />
          <Route path={ROUTES.SELFSERVE_SIGNUP} component={MarketerSignUpView} />
          <Route path={ROUTES.PRODUCT_MATRIX} component={ProductMatrix} />
          <Route render={() => { window.location.reload(); }}/>
        </Switch>
      </Stld.ContentRoot>
    </ConfigProvider>
  )
}

export default withRouter(App)
