export const USER_REGISTRATION_STARTED = 'USER_REGISTRATION_STARTED'
export const USER_REGISTRATION_FAILURE = 'USER_REGISTRATION_FAILURE'
export const USER_REGISTRATION_SUCCESS = 'USER_REGISTRATION_SUCCESS'
export const USER_REGISTRATION_IDP_PROVIDED = 'USER_REGISTRATION_IDP_PROVIDED'

export const INITIAL_STATE = {
    error: null,
    loading: false,
    success: false,
    idpInitiated: false
};

const registrationReducer = (
  state = INITIAL_STATE,
  { type, payload }
) => {
  switch (type) {
    case USER_REGISTRATION_STARTED:
      return {
        ...state,
        loading: true,
        error: undefined,
        success: false
      };
    case USER_REGISTRATION_FAILURE:
      return {
        ...state,
        error: payload,
        loading: false,
        success: false
      };
    case USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        success: true
      }
    case USER_REGISTRATION_IDP_PROVIDED:
      return {
        ...state,
        error: null,
        loading: false,
        success: true,
        idpInitiated: true
      }
    default:
      return state;
  }
};

export default registrationReducer;
