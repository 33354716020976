import React from 'react';
import styled from 'styled-components';

const PinnedCardContainer = styled.div`
  ${({ theme }) => theme && `
    background: ${theme === 'dark' ? 'var(--matter-dark-blue)' : 'var(--gray-1)'};
  `}
  border-radius: 8px;
  padding: 51px 51px 30px 51px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
  transition: all 2s ease-in-out;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    ${({ theme }) => theme && `
      background: ${theme === 'dark' ? 'var(--matter-bright-blue-light)' : 'var(--matter-gray-1)'};
    `}
    position: absolute;
    top: 20px;
    right: 20px;
  }

  &::after {
    content: '';
    width: 16px;
    height: 16px;
    border-radius: 8px;
    ${({ theme }) => theme && `
      background: ${theme === 'dark' ? 'var(--matter-bright-blue-light)' : 'var(--matter-gray-1)'};
    `}
    position: absolute;
    top: 20px;
    left: 20px;
  }
`

const PinnedCard = ({ children, theme = 'dark' }) => {
  return (
    <PinnedCardContainer theme={theme} >
      {children}
    </PinnedCardContainer>
  )
}

export default PinnedCard;