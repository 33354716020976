import { INITIAL_STATE } from './initialState';

const profileReducer = (
  state = INITIAL_STATE,
  action
  ) => {
    switch (action.type) {
      case 'FETCHING_PROFILE':
        return { ...state, fetchingProfile: true, profileFetched: false, error: null };
      case 'SAVING_PROFILE':
        return { ...state, savingProfile: true, profileSaved: false, error: null };
      case 'CHANGING_PASSWORD':
        return { ...state, passwordSaved: false, changingPassword: true, error: null };
      case 'FETCH_PROFILE_SUCCESS': {
        const { profile, idpProvided } = action
        return {
          ...state,
          fetchingProfile: false,
          profileFetched: true,
          idpProvided,
          profile
        };
      }
      case 'SAVE_PROFILE_SUCCESS': {
        const { profile } = action
        return {
          ...state,
          savingProfile: false,
          profileSaved: true,
          profile
        }
      }
      case 'CHANGE_PASSWORD_SUCCESS':
        return {
          ...state,
          changingPassword: false,
          passwordSaved: true
        };
      case 'FETCH_PROFILE_FAILURE': {
        const { error } = action
        return { 
          ...state, 
          fetchingProfile: false, 
          profileFetched: false, 
          error: error 
        };
      }
      case 'SAVE_PROFILE_FAILURE': {
        const { error } = action
        return {
          ...state,
          savingProfile: false,
          profileSaved: false,
          error
        }
      }
      case 'CHANGE_PASSWORD_FAILURE': {
        const { error } = action
        return {
          ...state,
          changingPassword: false,
          passwordSaved: false,
          error
        };
      }
      default:
        return state;
  }
};

export default profileReducer;